import {
    homeButtonStyle,
    headerBackgroundStyle,
    headerContentStyle,
    signInButtonStyle,
    signUpButtonStyle,
} from "./LandingHeader.style";
import autoBackorderLogo1 from "../../assets/images/headerHorizontalLogo.webp";
import autoBackorderLogo2 from "../../assets/images/headerVerticalLogo.webp";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { headerHeight } from "../SharedStyles";

function LandingHeader() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    return (
        <Box
            sx={{
                ...headerBackgroundStyle,
                height: headerHeight,
            }}
        >
            <Box sx={headerContentStyle}>
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                >
                    <Grid item>
                        <Box display={{ xs: "none", sm: "flex" }}>
                            <img
                                id="inside-logo-horizontal"
                                src={autoBackorderLogo1}
                                alt="Logo"
                                loading="lazy"
                                style={{
                                    height: "40px",
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate("/")}
                            />
                        </Box>
                        <Box display={{ xs: "flex", sm: "none" }}>
                            <img
                                id="inside-logo-vertical"
                                src={autoBackorderLogo2}
                                alt="Logo"
                                loading="lazy"
                                style={{
                                    height: "35px",
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate("/")}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        display="flex"
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        xs={6}
                    >
                        <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            columnSpacing={2}
                        >
                            {!(location.pathname === "/") && (
                                <Grid item>
                                    <Box
                                        sx={homeButtonStyle}
                                        onClick={() => navigate("/")}
                                    >
                                        Home
                                    </Box>
                                </Grid>
                            )}
                            {!auth.isLogged ? (
                                <>
                                    {location.pathname === "/" && (
                                        <Grid item>
                                            <Button
                                                sx={signInButtonStyle}
                                                onClick={() =>
                                                    navigate("/login")
                                                }
                                            >
                                                Sign In
                                            </Button>
                                        </Grid>
                                    )}
                                    {location.pathname === "/" && (
                                        <Grid item>
                                            <Button
                                                sx={signUpButtonStyle}
                                                onClick={() =>
                                                    navigate("/register")
                                                }
                                            >
                                                Sign Up
                                            </Button>
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <Grid item>
                                    <Box
                                        sx={homeButtonStyle}
                                        onClick={() =>
                                            navigate("/dashboard/preBids")
                                        }
                                    >
                                        Dashboard
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default LandingHeader;
