const headerItemWidth = { xs: "65px", sm: "90px" };

export const signInButtonStyle = {
    borderRadius: "18px",
    border: 2,
    borderColor: "#EFD016",
    backgroundColor: "#EFD016",
    height: { xs: "23px", sm: "30px" },
    width: headerItemWidth,
    color: "black",
    fontWeight: 500,
    textTransform: "none",
    fontSize: { xs: "10px", sm: "15px" },
    "&:hover": {
        backgroundColor: "#EFD016",
    },
};

export const signUpButtonStyle = {
    borderRadius: "18px",
    border: 2,
    borderColor: "#EFD016",
    backgroundColor: "#000000",
    height: { xs: "23px", sm: "30px" },
    width: headerItemWidth,
    color: "#EFD016",
    fontWeight: 500,
    textTransform: "none",
    fontSize: { xs: "10px", sm: "15px" },
};

export const homeButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#EFD016",
    fontFamily: "Inter",
    cursor: "pointer",
};

export const headerContentStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingX: { xs: "20px", sm: "50px" },
    height: "100%",
};

export const headerBackgroundStyle = {
    width: "100%",
    zIndex: 1,
    background: "linear-gradient(135deg, #efd016 48%, black 48%)",
};
