export const darkYellow = "#F1D631";
export const lightYellow = "#EFD016";

export const buttonStyles = {
    color: "#3A3541CC",
    backgroundColor: lightYellow,
    borderRadius: 20,
    border: "none",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    padding: 1,
    "&.MuiButton-root:hover": {
        color: "#3A3541CC",
        backgroundColor: darkYellow,
        border: "none",
    },
    width: { md: "auto", sm: "100%" },
};

export const bidButtonStyles = {
    color: "#3A3541CC",
    backgroundColor: lightYellow,
    borderRadius: 20,
    border: "none",
    textTransform: "none",
    fontSize: "14px",
    px: 2,
    py: 1,
    "&.MuiButton-root:hover": {
        color: "#3A3541CC",
        backgroundColor: darkYellow,
        border: "none",
    },
    width: "100%",
    fontWeight: 600,
};
