import { Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { BsXLg } from "react-icons/bs";

const PopupModelWrapper = ({
    children,
    handleClose,
    open,
    title,
    width,
}: {
    children: React.ReactNode;
    handleClose: () => void;
    open: boolean;
    title?: string;
    width?: any;
}) => {
    const styles = {
        container: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width ? width : { xs: "300px", sm: "300px", md: "500px" },
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: "none",
            p: 1,
        },
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id={"popup-modal-" + title}
        >
            <Stack sx={styles.container}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#3A3541CC",
                        }}
                    >
                        {title ? title : " "}
                    </Typography>
                    <BsXLg
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                        id={"popup-modal-button-" + title}
                    />
                </Stack>
                {children}
            </Stack>
        </Modal>
    );
};

export default PopupModelWrapper;
