import backgroundPrimary from "../../assets/images/landingPageMain.webp";
import backgroundSecondary from "../../assets/images/landingPageSecondary.webp";

export const imageBackground = {
    backgroundImage: {
        xs: `url(${backgroundSecondary})`,
        md: `url(${backgroundPrimary})`,
    },
    backgroundSize: {
        xs: "90% 60%",
        sm: "75% 80%",
        md: "80% 90%",
        lg: "auto 98%",
    },
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
};

export const mainContainerStyle = {
    ...imageBackground,
    backgroundColor: "black",
    minHeight: { xs: "400px", sm: "600px" },
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const mainTextContainer = {
    marginLeft: "8%",
    marginRight: "8%",
};

const mainTextColor = "white";

export const topicStyle = {
    fontSize: { xs: "25px", sm: "30px", lg: "50px" },
    fontWeight: 700,
    textAlign: { xs: "center", sm: "justify" },
    color: mainTextColor,
    lineHeight: 1,
};

export const contentStyle = {
    fontSize: { xs: "18px", sm: "16px" },
    textAlign: { xs: "center", sm: "justify" },
    lineHeight: { xs: "30px", md: "35px" },
    color: mainTextColor,
    mt: "30px",
    mb: "30px",
    width: { sm: "90%", md: "68%", lg: "40%" },
};

export const listItemStyle = {
    display: "-webkit-box",
    flexDirection: "column",
    marginBottom: "8px",
};

export const listItemTextStyle = {
    fontSize: "18px",
    "&:hover": {
        transform: "translateX(5px)",
        transition: "all 0.5s",
    },
    transition: "all 0.5s",
    color: mainTextColor,
};

export const iconStyle = {
    marginRight: "10px",
    color: "yellow",
};

export const buttonStyle = {
    borderRadius: "20px",
    height: "35px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#EFD016",
    color: "black",
    textTransform: "none",
    fontSize: "15px",
    marginTop: "30px",
    "&:hover": {
        backgroundColor: "#EFD016",
    },
};

export const scrollArrowStyle = {
    display: { xs: "None", md: "flex" },
    color: "white",
    opacity: 0.8,
    position: "absolute",
    bottom: 30,
    left: "50%",
    cursor: "pointer",
    fontSize: "45px",

    transform: "translateX(-50%)",

    animation: "bounce 1.5s infinite",
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0) translateX(-50%)",
        },
        "40%": {
            transform: "translateY(-20px) translateX(-50%)",
        },
        "60%": {
            transform: "translateY(-10px) translateX(-50%)",
        },
    },
    "&:hover": {
        animation: "none",
    },
};
