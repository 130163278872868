import Grid from "@mui/material/Grid";
import ExpandableComponent from "../ExpandableComponent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
    cardContentTextStyle,
    cardHeaderStyle,
    cardStyle,
    faqRowSpacing,
    gridContainerStyle,
    gridItemStyle,
} from "./Features.style";
import FeatureCard from "../featureCard/FeatureCard";
import { faqData, featureData } from "./FeaturesData";
import { useMediaQuery, useTheme } from "@mui/material";

function Features({ smoothScrollToRef }: { smoothScrollToRef: any }) {
    const theme = useTheme();
    const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Card
                ref={smoothScrollToRef}
                sx={{ ...cardStyle, paddingTop: { xs: "20px", sm: "40px" } }}
            >
                <Typography
                    sx={{ ...cardHeaderStyle, color: "black" }}
                    align={"center"}
                >
                    Backorder Expired Domains for Your Next Digital Venture
                </Typography>
                <Typography
                    sx={cardContentTextStyle}
                    align={isBelowMd ? "justify" : "center"}
                    width={isBelowMd ? "90%" : undefined}
                >
                    {
                        "Our next-generation drop catching system makes the process of registering expired domain names easier than ever. Login to the user- friendly control panel and within just a few clicks you can backorder expired domains in virtually every extension that's available to the public!"
                    }
                </Typography>
                <Typography sx={cardHeaderStyle} align={"center"}>
                    Our Features
                </Typography>
                <Grid container sx={gridContainerStyle}>
                    {featureData.map((feature, index) => (
                        <Grid item xs={12} sx={gridItemStyle} key={index}>
                            <FeatureCard
                                header={feature.header}
                                content={feature.content}
                                image={feature.image}
                                imagePosition={((index + 1) % 2) as 0 | 1}
                                arrangeVertically={isBelowMd}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Card>
            <Card
                sx={{ ...cardStyle, paddingBottom: { xs: "20px", sm: "40px" } }}
            >
                <Typography sx={cardHeaderStyle} align={"center"}>
                    FAQ
                </Typography>
                <Typography
                    sx={cardContentTextStyle}
                    align={isBelowMd ? "justify" : "center"}
                    width={isBelowMd ? "90%" : undefined}
                >
                    {
                        "We understand you might have some questions before trying out our services. Listed below are some of the most commonly asked questions and answers related to our expired domain drop catching services. If you have any questions that are not answered here then contact us and we'll be happy to assist you further."
                    }
                </Typography>
                <Grid
                    container
                    sx={{
                        ...gridContainerStyle,
                        backgroundColor: "#fff8e6",
                        marginTop: "20px",
                    }}
                    rowSpacing={faqRowSpacing}
                >
                    {faqData.map((item, index) => (
                        <Grid
                            item
                            xs={12}
                            key={index}
                            sx={{
                                marginBottom:
                                    index === faqData.length - 1
                                        ? faqRowSpacing
                                        : undefined,
                            }}
                        >
                            <ExpandableComponent
                                question={item.question}
                                message={item.message}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </>
    );
}

export default Features;
