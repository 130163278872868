import { Fragment, useRef } from "react";
import About from "../../components/about/About";
import Features from "../../components/features/Features";
import { headerHeight } from "src/components/SharedStyles";

function LandingPage() {
    const scrollToRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (scrollToRef.current) {
            const element = scrollToRef.current;
            const offset = parseFloat(headerHeight.replace("px", ""));

            window.scrollTo({
                top: element.offsetTop - offset,
                behavior: "smooth",
            });
        }
    };

    return (
        <Fragment>
            <About smoothScroll={handleScroll} />
            <Features smoothScrollToRef={scrollToRef} />
        </Fragment>
    );
}

export default LandingPage;
