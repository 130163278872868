import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { OrderPropsType, data } from "src/types/messagesPageTypes";
import useData from "src/hooks/useApi";
import TablePagination from "src/components/table/TablePagination";
import { PaginationPropsType } from "src/types/paginationTypes";
import { calcPaginationProps } from "src/utils/helperFunctions";
import { OrderTypes } from "src/enums/sortingsEnum";
import MessagesPageTable from "src/components/messages/MessagesPageTable";
import { LevelEnum } from "src/enums/messagesEnum";
import MultiDropDown from "src/components/common/MultiDropDown";
import { DateTime } from "luxon";
import { payloadType } from "src/types/dataFetchingTypes";
import useMessage from "src/hooks/useMessage";
import DateRangePicker from "src/components/common/DateRangePicker";
import { datePickerItem, filterItem } from "../preBids/PreBidsStyle";
import {
    mainContentContainer,
    pageHeaderStyle,
} from "src/components/SharedStyles";

const MessagesPage = () => {
    const api = useData();
    const { showError } = useMessage();
    const [tableData, setTableData] = useState<data[]>([]);
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [messageProps, setMessageProps] = useState<OrderPropsType>({
        order: OrderTypes.DESC,
        orderBy: "sentAt",
    });
    const [selectedLevels, setSelectedLevels] = useState<LevelEnum[]>([]);
    const levels = [
        {
            label: "Info",
            value: LevelEnum.INFO,
        },
        {
            label: "Warning",
            value: LevelEnum.WARN,
        },
        {
            label: "Danger",
            value: LevelEnum.DANGER,
        },
    ];
    const [endDate, setEndDate] = useState(DateTime.now());
    const [startDate, setStartDate] = useState(
        DateTime.now()
            .plus({ days: -1 })
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    const [loading, setLoading] = useState<boolean>(false);

    async function getData(isReset: boolean = false) {
        setLoading(true);
        console.log(startDate.toSeconds(), endDate.toSeconds());
        const payload: payloadType = {
            page: paginationProps.currentPage,
            size: paginationProps.rowsPerPage,
            filterAnd: [
                {
                    key: "sentAt",
                    operation: ">",
                    value: Math.round(startDate.toSeconds()),
                },
                {
                    key: "sentAt",
                    operation: "<",
                    value: Math.round(endDate.toSeconds()),
                },
            ],
            sort: {
                key: messageProps.orderBy,
                order: messageProps.order.toUpperCase(),
            },
        };
        if (isReset) {
            payload.page = 1;
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }
        try {
            if (selectedLevels.length > 0) {
                if (payload.filterAnd)
                    payload.filterAnd.push({
                        key: "level",
                        operation: "IN",
                        value: selectedLevels,
                    });
            }
            const response: any = await api?.postData(
                "/messages/getUserMessages",
                payload,
            );
            if (response.data) {
                setTableData(response.data);
            } else {
                setTableData([]);
            }
            setPaginationProps((paginationProps) =>
                calcPaginationProps(paginationProps, response.count),
            );
        } catch (error) {
            setTableData([]);
            showError("Data fetching error");
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        messageProps.orderBy,
        messageProps.order,
        // api,
    ]);

    useEffect(() => {
        getData(true);
    }, [selectedLevels, endDate, startDate, paginationProps.rowsPerPage]);

    return (
        <Box alignItems="center" sx={mainContentContainer}>
            <Grid
                container
                justifyContent={"space-between"}
                paddingBottom={mainContentContainer.padding}
                rowSpacing={2}
            >
                <Grid item xs={12}>
                    <Typography sx={pageHeaderStyle} align={"left"}>
                        All Messages
                    </Typography>
                </Grid>
                <Grid item {...filterItem}>
                    <Grid container rowSpacing={2.5} columnSpacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MultiDropDown
                                checkIcon={false}
                                data={levels}
                                label="Level"
                                selectedItems={selectedLevels}
                                setSelectedItems={setSelectedLevels}
                                containerStyles={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item {...datePickerItem}>
                            <DateRangePicker
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                startDate={startDate}
                                maxDate={DateTime.now()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <MessagesPageTable
                apiData={tableData}
                orderProps={messageProps}
                setOrderProps={setMessageProps}
                loading={loading}
            />
            <TablePagination
                paginationProps={paginationProps}
                setPaginationProps={setPaginationProps}
            />
        </Box>
    );
};

export default MessagesPage;
