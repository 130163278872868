import { Box } from "@mui/material";
import LoadingIcon from "./LoadingIcon";

const PopupLoading = () => {
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            height="100%"
        >
            <LoadingIcon />
        </Box>
    );
};

export default PopupLoading;
