import { keyframes } from "@mui/material";
import { mainContentPadding } from "src/components/SharedStyles";

export const containerStyle = {
    padding: mainContentPadding,
    display: "flex",
    flexDirection: { xs: "column" },
    borderRadius: 1,
    bgcolor: "#FFFFFF",
    height: "auto",
};

export const shadedContainerStyle = {
    py: { xs: 1, sm: 2 },
    px: { xs: 1, sm: 2 },
    backgroundColor: "#fafafa",
    marginBottom: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    width: "100%",
};

export const bidInputStyle = {
    fontSize: "14px",
    borderRadius: 20,
    minWidth: "100%",
    maxHeight: 40,
};

export const inlineTextStyle = {
    display: "inline",
    marginLeft: "5px",
};

export const bulletStyle = {
    marginTop: "8px",
    fontSize: "8px",
    color: "#615d67",
};

export const centredGridItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const auctionStatusBaseChip = {
    color: "white",
    height: "20px",
    fontSize: "10px",
    width: "50px",
};

export const liveChipStyle = {
    ...auctionStatusBaseChip,
    backgroundColor: "#3bb800",
    minWidth: "45px",
};

export const endedChipStyle = {
    ...auctionStatusBaseChip,
    backgroundColor: "#efd016",
    minWith: "60px",
};

export const auctionTableHeader = {
    divider: {
        height: "30px",
        bgcolor: "#D9D9D9",
        width: "2px",
        borderRadius: "15px",
        position: "absolute",
        right: 0,
        top: 13,
    },
};

export const flexEndAlign = {
    display: "flex",
    justifyContent: { xs: "flex-start", lg: "flex-end" },
    overflow: "hidden",
    alignItems: "center",
    fontFamily: "'Inter', sans-serif",
};

const baseStatusContainer = {
    borderWidth: "1px",
    borderStyle: "solid",
    height: "30px",
    borderRadius: "10px",
    marginLeft: "10px",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    fontSize: { xs: "12px", sm: "14px" },
    whiteSpace: "nowrap",
    overflow: "auto",
    textOverflow: "ellipsis",
    width: { xs: "60%", sm: "55%", md: "38%", lg: "45%", xl: "35%" },
};

export const highBidStatusContainer = {
    ...baseStatusContainer,
    backgroundColor: "#def3d4",
    borderColor: "#3bb800",
    color: "#3bb800",
};

export const outBidStatusContainer = {
    ...baseStatusContainer,
    backgroundColor: "#ffd2d3",
    borderColor: "#ff4d4f",
    color: "#ff4d4f",
};

export const GridStyle = {
    padding: mainContentPadding,
};

export const TypographyContentStyle = {
    fontSize: "14px",
};

export const TypographyBoldContentStyle = {
    fontSize: "14px",
    fontWeight: 600,
};

export const TypographyHeaderStyle = {
    fontSize: "15px",
    marginBottom: "10px",
    marginTop: "-5px",
    fontWeight: 600,
    color: "#615d67",
};

export const TypographyTimeStyle = {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "10px",
    marginLeft: "-4.6px",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "auto",
};

export const TypographyDomainStyle = {
    fontSize: "40px",
    fontWeight: 700,
    marginBottom: "10px",
    marginLeft: "-2px",
};

export const TypographyCurrencyStyle = {
    fontSize: "40px",
    fontWeight: 600,
    marginLeft: "-2px",
};

// rgba(239, 208, 22, 0.7) Matched to lightYellow defined for button

export const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(239, 208, 22, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 2px rgba(239, 208, 22, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(239, 208, 22, 0.7);
  }
`;

// Distance From Anchor to Tooltip

export const toolTipMargin = {
    popper: {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, -12],
                },
            },
        ],
    },
};

export const AuctionInfoGridStyle = {
    borderRadius: 1,
    bgcolor: "#FFFFFF",
    padding: mainContentPadding,
};
