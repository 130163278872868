import { Box, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import DateRangePicker from "src/components/common/DateRangePicker";
import MultiDropDown from "src/components/common/MultiDropDown";
import SearchField from "src/components/common/SearchField";
import SubmissionsTable from "src/components/submissions/SubmissionsTable";
import { OrderPropsType, data } from "src/types/submissionsTypes";
import { OrderTypes } from "src/enums/sortingsEnum";
import { PaginationPropsType } from "src/types/paginationTypes";
import TablePagination from "src/components/table/TablePagination";
import { calcPaginationProps } from "src/utils/helperFunctions";
import useMessage from "src/hooks/useMessage";
import { payloadType } from "src/types/dataFetchingTypes";
import useData from "src/hooks/useApi";
import {
    datePickerItem,
    filterItem,
    multipleSelectItem,
    searchItem,
} from "../preBids/PreBidsStyle";
import {
    mainContentContainer,
    pageHeaderStyle,
} from "src/components/SharedStyles";

const statusSelections = [
    {
        label: "Pending",
        value: "Pending",
    },
    {
        label: "Success",
        value: "Success",
    },
    {
        label: "Failure",
        value: "Failure",
    },
];

const SubmissionsPage = () => {
    const { showError } = useMessage();
    const { postData } = useData();

    // Filters and searches
    const [endDate, setEndDate] = useState(DateTime.now());
    const [startDate, setStartDate] = useState(
        DateTime.now()
            .plus({ days: -1 })
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

    // common
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [tableData, setTableData] = useState<data[]>([]);
    const [orderProps, setOrderProps] = useState<OrderPropsType>({
        order: OrderTypes.DESC,
        orderBy: "date",
    });
    const [resubmitTrigger, setResubmitTrigger] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    async function getData(isReset: boolean = false) {
        setLoading(true);
        // console.log(startDate.toSeconds(), endDate.toSeconds());
        const payload: payloadType = {
            page: paginationProps.currentPage,
            size: paginationProps.rowsPerPage,
            filterAnd: [
                {
                    key: "date",
                    operation: ">",
                    value: Math.round(startDate.toSeconds()),
                },
                {
                    key: "date",
                    operation: "<",
                    value: Math.round(endDate.toSeconds()),
                },
            ],
            sort: {
                key: orderProps.orderBy,
                order: orderProps.order.toUpperCase(),
            },
        };
        if (isReset) {
            payload.page = 1;
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }
        try {
            if (selectedStatus.length > 0) {
                if (payload.filterAnd)
                    payload.filterAnd.push({
                        key: "status",
                        operation: "IN",
                        value: selectedStatus,
                    });
            }
            if (searchValue.trim().length > 0) {
                if (payload.filterAnd)
                    payload.filterAnd.push({
                        key: "domain",
                        operation: "LIKE",
                        value: [`${searchValue.trim()}%`],
                    });
            }
            const response: any = await postData(
                "/submissions/getUserSubmissions",
                payload,
            );
            // const response: any = submissionsRequestData;
            if (response.data) {
                setTableData(response.data);
                // console.log(response.data);
            } else {
                setTableData([]);
            }
            setPaginationProps((paginationProps) =>
                calcPaginationProps(paginationProps, response.count),
            );
        } catch (error) {
            setTableData([]);
            showError("Data fetching error");
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        orderProps.orderBy,
        orderProps.order,
        selectedStatus,
        endDate,
        startDate,
        paginationProps.rowsPerPage,
        resubmitTrigger,
    ]);

    // To Clear the Filter on Search Field Clear
    useEffect(() => {
        if (!searchValue) getData(true);
    }, [searchValue]);

    useEffect(() => {
        if (tableData.length <= 0 && paginationProps.currentPage !== 1) {
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }
    }, [tableData]);

    const handleChangeSearch = (e: any) => {
        setSearchValue(e.target.value.trim().toLowerCase());
    };

    return (
        <Box alignItems="center" sx={mainContentContainer}>
            <Grid
                container
                justifyContent={"space-between"}
                paddingBottom={mainContentContainer.padding}
                rowSpacing={2}
            >
                <Grid item xs={12}>
                    <Typography sx={pageHeaderStyle} align={"left"}>
                        Submission History
                    </Typography>
                </Grid>
                <Grid item {...filterItem}>
                    <Grid container rowSpacing={2.5} columnSpacing={2}>
                        <Grid item {...searchItem}>
                            <SearchField
                                iconPosition="start"
                                onChange={handleChangeSearch}
                                placeholder="Search Domain"
                                value={searchValue}
                                id="Search-button-all-bids"
                                keyPressTrigger={(e) => {
                                    if (
                                        e.code === "Enter" ||
                                        e.key === "Enter"
                                    ) {
                                        getData(true);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item {...multipleSelectItem}>
                            <MultiDropDown
                                id="all-bids-status-dropdown"
                                label="Progress Status"
                                checkIcon={false}
                                setSelectedItems={setSelectedStatus}
                                selectedItems={selectedStatus}
                                data={statusSelections}
                                containerStyles={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item {...datePickerItem}>
                            <DateRangePicker
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                startDate={startDate}
                                maxDate={DateTime.now()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SubmissionsTable
                apiData={tableData}
                orderProps={orderProps}
                setOrderProps={setOrderProps}
                setResubmitTrigger={setResubmitTrigger}
                setEndDate={setEndDate}
                loading={loading}
            />
            <TablePagination
                paginationProps={paginationProps}
                setPaginationProps={setPaginationProps}
            />
        </Box>
    );
};

export default SubmissionsPage;
