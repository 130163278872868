import {
    Button,
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { dateTimeConvertor } from "src/utils/helperFunctions";
import EmptyTable from "../table/EmptyTable";
import AllBidsHeadRow from "./AllBidsHeadRow";
import { data, HeadingType, OrderPropsType } from "src/types/allBidsPageTypes";
import ProgressStatusChip from "../common/ProgressStatusChip";
import MyBackordersActionMenu from "./MyBackordersActionMenu";
import {
    ProgressStatusEnum,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import LoadingTable from "../table/LoadingTable";
import Big from "big.js";
import { AuctionStatusEnum } from "../../types/postAuctionTypes";
import { BsPencilSquare } from "react-icons/bs";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import Grid from "@mui/material/Grid";
import { StyledTableCell } from "../SharedStyles";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const psl = require("psl");

const headRow: HeadingType[] = [
    {
        id: "domainName",
        label: "Domain",
        align: "left",
        sorting: true,
    },
    {
        id: "priority",
        label: "Priority",
        align: "center",
        sorting: true,
    },
    {
        id: "amount",
        label: "My Bid ($)",
        align: "right",
        sorting: true,
    },
    {
        id: "status",
        label: "Progress Status",
        align: "center",
    },
    {
        id: "dropTime",
        label: "Drop Date",
        align: "center",
        sorting: true,
    },
    {
        id: "bidders",
        label: "Bidders",
        align: "center",
        sorting: true,
    },
    {
        id: "action",
        label: "Actions",
        align: "center",
    },
];

const PreBidsPageTable = ({
    apiData,
    orderProps,
    setOrderProps,
    popupHandlers,
    loading,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    popupHandlers: {
        update: () => void;
        delete: () => void;
        topUp: (message: string, defaultAmount: number) => void;
    };
    loading?: boolean;
}) => {
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<data[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    function convertStatus(
        status: ProgressStatusEnum,
        auctionStatus: AuctionStatusEnum,
    ) {
        if (auctionStatus == AuctionStatusEnum.PAYMENT_PENDING) {
            return UserStatusEnum.PAYMENT;
        }

        if (
            status === ProgressStatusEnum.WAITING ||
            status === ProgressStatusEnum.INPROGRESS
        ) {
            return UserStatusEnum.INPROGRESS;
        }

        if (status === ProgressStatusEnum.PENDING) {
            return UserStatusEnum.SUBMISSIONPEND;
        }

        if (status === ProgressStatusEnum.RESULT_PENDING) {
            return UserStatusEnum.RESULT_PENDING;
        }

        return UserStatusEnum.PENDING;
    }

    function getDate(date: number) {
        return date ? dateTimeConvertor(+date).date : "";
    }

    useEffect(() => {
        setRows(apiData);
    }, [apiData]);

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    const { postData } = useData();
    const { showError } = useMessage();
    const { setPopupState } = useBidsPopupState();

    async function handleUpdate(row: any) {
        if (row.domainName && row.priority) {
            const tld = psl.parse(row.domainName).tld;
            try {
                const response: any = await postData("/tld/getPrices", {
                    tlds: [psl.parse(row.domainName).tld],
                });
                if (response.success) {
                    const tldData = response.data.find(
                        (tldData: any) => tldData.tld === tld,
                    );
                    setPopupState({
                        id: row ? row.id : 0,
                        domain: row ? row.domainName : "",
                        priority: row ? row.priority : null,
                        tld,
                        regFee: tldData.regFee,
                        successFee: tldData.successFee,
                    });
                    popupHandlers.update();
                } else {
                    throw new Error("request failed");
                }
            } catch (error) {
                showError("TLD data unavailable");
            }
        }
    }

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <AllBidsHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={headRow}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={headRow.length} />
                    ) : apiData.length > 0 ? (
                        rows.map((row) => {
                            const status = convertStatus(
                                row.status,
                                row.auctionStatus,
                            );
                            return (
                                <StyledTableRow key={row.domainName}>
                                    <StyledTableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                    >
                                        {row.domainName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        <Grid
                                            container
                                            display="flex"
                                            justifyContent={"center"}
                                            spacing={1}
                                        >
                                            <Grid item>{row.priority}</Grid>
                                            <Grid item>
                                                {row.status ==
                                                    ProgressStatusEnum.INPROGRESS ||
                                                    (row.status ==
                                                        ProgressStatusEnum.WAITING && (
                                                        <Button
                                                            onClick={() => {
                                                                handleUpdate(
                                                                    row,
                                                                );
                                                            }}
                                                            sx={{
                                                                minWidth:
                                                                    "12px",
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <BsPencilSquare
                                                                style={{
                                                                    color: "#21B2E7",
                                                                    fontSize:
                                                                        "13px",
                                                                    justifyContent:
                                                                        "flex-end",
                                                                }}
                                                            />
                                                        </Button>
                                                    ))}
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        component="th"
                                        scope="row"
                                    >
                                        {row.amount
                                            ? Big(row.amount).toFixed(2)
                                            : null}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <ProgressStatusChip chipType={status} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        {row.status !=
                                        ProgressStatusEnum.PENDING
                                            ? getDate(row.dropTime).toString()
                                            : null}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        {row.bidders}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        <MyBackordersActionMenu
                                            id="RowMenu"
                                            data={row}
                                            popupHandlers={popupHandlers}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })
                    ) : (
                        <EmptyTable msg="No bids" colSpan={headRow.length} />
                    )}
                </TableBody>
            </Table>
            {false && (
                <TablePagination
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e: unknown, newPage: number) => {
                        setPage(newPage);
                    }}
                    onRowsPerPageChange={(e: any) => {
                        setRowsPerPage(e.target.value);
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            )}
        </TableContainer>
    );
};

export default PreBidsPageTable;
