import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

export const mainContainerMargin = { xs: 3, lg: 4, xl: 5 };
export const mainContentPadding = { xs: 3, lg: 4, xl: 5 };

export const mainContentContainer = {
    margin: mainContainerMargin,
    padding: mainContentPadding,
    display: "flex",
    flexDirection: { xs: "column" },
    borderRadius: 1,
    bgcolor: "#FFFFFF",
};

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFAFA",
        p: 1,
        whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.body}`]: {
        color: "#666666",
        fontWeight: 400,
        fontSize: { xs: 10, sm: 14 },
    },
}));

export const headerFontSize = {
    xs: "20px",
    md: "25px",
    lg: "32px",
};

export const innerHeaderFontSize = {
    xs: "20px",
    md: "25px",
    lg: "22px",
};

export const contentFontSize = {
    xs: "14px",
    lg: "16px",
};

export const pageHeaderStyle = {
    fontSize: innerHeaderFontSize,
    fontWeight: 600,
    marginBottom: "15px",
    color: "#666666",
};

export const headerHeight = "60px";
export const dataStripHeight = "38px";

export const pageHeightWithoutHeader = `calc(100dvh - ${headerHeight})`;
export const headerHeightWithDataStrip = `calc(${headerHeight} + ${dataStripHeight})`;

export const headerContainerStyle = {
    position: "fixed",
    width: "100%",
    zIndex: "10",
};

export const contentWithoutAuthStyle = {
    paddingTop: headerHeight,
};
