import { InputAdornment, OutlinedInput } from "@mui/material";
import { BsSearch } from "react-icons/bs";

const SearchField = ({
    id,
    placeholder,
    value,
    onChange,
    iconPosition,
    width,
    keyPressTrigger,
    maxHeight,
}: {
    id?: string;
    placeholder: string;
    value: string;
    onChange: (e: any) => void;
    iconPosition: "start" | "end";
    width?: number;
    maxHeight?: number | string;
    keyPressTrigger?: (e: any) => void;
}) => {
    const commonStyles = {
        "& .MuiInputBase-input::placeholder": {
            color: "black",
            opacity: 0.6,
        },
    };

    if (iconPosition === "start")
        return (
            <OutlinedInput
                type="search"
                id={id}
                sx={{
                    ...commonStyles,
                    fontSize: "14px",
                    borderRadius: 20,
                    "& .MuiOutlinedInput-input": {
                        py: "12px",
                        fontSize: "14px",
                    },
                    maxHeight: "100%",
                    width: "100%",
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <BsSearch />
                    </InputAdornment>
                }
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={keyPressTrigger}
            />
        );
    else
        return (
            <OutlinedInput
                type="search"
                id={id}
                sx={{
                    ...commonStyles,
                    fontSize: "14px",
                    borderRadius: 20,
                    minWidth: width ?? 300,
                    "& .MuiOutlinedInput-input": {
                        py: "12px",
                        fontSize: "14px",
                    },
                    mr: 2,
                    maxHeight: maxHeight ?? 45,
                }}
                endAdornment={
                    <InputAdornment position="start">
                        <BsSearch />
                    </InputAdornment>
                }
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={keyPressTrigger}
            />
        );
};

export default SearchField;
