import { paymentErrorLog, paymentLog } from "./logger";
import { ErrorSources, LogLevels } from "../enums/loggerEnums";

export async function dataPopupWebhookReceived(
    details: any,
    activeChipButton: number,
    auth: any,
    api: any,
) {
    try {
        if (details?.reference && details?.id) {
            const response: any = await api.postData(
                "/transaction/createTransaction",
                {
                    amount: `${activeChipButton}`,
                    id: details.id,
                    reference: details.reference,
                },
            );
            console.log(response);
            paymentLog({
                level: LogLevels.SUCCESS,
                message: "Payment success",
                source: ErrorSources.FS,
                username: auth.user?.username || "",
                email: auth.user?.email || "",
                paymentId: details.id,
                referenceId: details.reference,
            });
            if (!response || !response?.success) {
                paymentLog({
                    level: LogLevels.ERROR,
                    message: "Transaction request failed",
                    source: ErrorSources.BACKEND,
                    username: auth.user?.username || "",
                    email: auth.user?.email || "",
                    paymentId: details.id,
                    referenceId: details.reference,
                });
            }
        } else {
            paymentLog({
                level: LogLevels.ERROR,
                message: "Webhook not received to frontend",
                source: ErrorSources.FS,
                username: auth.user?.username || "",
                email: auth.user?.email || "",
                paymentId: details.id,
                referenceId: details.reference,
            });
        }
    } catch (error: any) {
        paymentLog({
            level: LogLevels.ERROR,
            message: error?.response?.message,
            source: ErrorSources.FS,
            username: auth.user?.username || "",
            email: auth.user?.email || "",
            paymentId: details.id,
            referenceId: details.reference,
        });
    }
}

export function dataErrorCallback(code: number, message: string, auth: any) {
    console.log(code, message);
    paymentErrorLog({
        level: LogLevels.ERROR,
        message,
        code,
        email: auth.user?.email || "",
        source: ErrorSources.FS,
    });
}

export async function dataPopupClosed(details: any, showSuccess: any) {
    if (details?.reference && details?.id) {
        showSuccess("Page will refresh after the payment is confirmed");
    }
}

export const paymentLabels: any = {
    20: "autobackorder-balance-payment-20",
    50: "autobackorder-balance-payment-50",
    100: "autobackorder-balance-payment-100",
    200: "autobackorder-balance-payment-200",
    500: "autobackorder-balance-payment-500",
};

export const setupFastspring = (
    fastspring: any,
    activeChipButton: number,
    email: string,
    username: string,
) => {
    fastspring.builder.reset();
    fastspring.builder.secure({
        reset: true,
        contact: {
            email: email,
            firstName: "User",
            lastName: username,
        },
        products: [
            {
                path: `${paymentLabels[activeChipButton]}`,
                quantity: 1,
            },
        ],
    });
    fastspring.builder.checkout();
};
