import { Box, TableHead, TableRow, Typography } from "@mui/material";
import { BalanceHeadRowType, orderType } from "../../types/balancePageTypes";
import { dataType } from "../../types/balancePageTypes";
import { tableHead } from "./tableStyles";
import { OrderTypes } from "src/enums/sortingsEnum";
import { StyledTableCell } from "../SharedStyles";

const BalanceHeadRow = ({
    order,
    orderBy,
    setOrderProps,
    headRow,
}: BalanceHeadRowType) => {
    const setOrder = (order: orderType) => {
        setOrderProps((orderProps) => ({ ...orderProps, order }));
    };

    const setOrderBy = (orderBy: dataType) => {
        setOrderProps((orderProps) => ({ ...orderProps, orderBy }));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sortHandler = (id: dataType) => {
        if (orderBy !== id) {
            setOrder(OrderTypes.ASC);
        } else {
            switch (order) {
                case "asc":
                    setOrder(OrderTypes.DESC);
                    break;
                default:
                    setOrder(OrderTypes.ASC);
                    break;
            }
        }
        setOrderBy(id);
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    "& .MuiTableCell-head": {
                        position: "relative",
                    },
                }}
            >
                {headRow.map((field, index) => (
                    <StyledTableCell
                        size="small"
                        key={field.id}
                        align={field.align ?? "center"}
                    >
                        {/* <TableSortLabel
                            onClick={() => sortHandler(field.id)}
                            hideSortIcon
                            sx={tableHead.headStyle}
                        >
                            {field.label}
                            <TableSortIcon
                                type={order}
                                active={orderBy === field.id}
                            />
                        </TableSortLabel> */}

                        <Typography sx={tableHead.headStyle} paddingY={2}>
                            {field.label}
                        </Typography>

                        {!(index === headRow.length - 1) && (
                            <Box sx={tableHead.divider}></Box>
                        )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default BalanceHeadRow;
