import { Outlet } from "react-router-dom";
import InsideNavbar from "src/components/navigation/InsideNavbar";
import FixedDataStrip from "src/components/FixedDataStripe";
import useAuth from "src/hooks/useAuth";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import {
    headerContainerStyle,
    headerHeightWithDataStrip,
} from "src/components/SharedStyles";

const ProtectedRoute = () => {
    const auth = useAuth();

    if (!auth.isLogged || !auth.user) {
        return <Navigate to="/login" />;
    } else {
        return (
            <Grid container>
                <Grid item xs={12} sx={headerContainerStyle}>
                    <InsideNavbar />
                    <FixedDataStrip />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ paddingTop: headerHeightWithDataStrip }}
                >
                    <Outlet />
                </Grid>
            </Grid>
        );
    }
};

export default ProtectedRoute;
