import React, { createContext, useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { getToken } from "src/utils/jwtFunctions";

const NotificationContext = createContext<any>(null);

export const NotificationsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const auth = useAuth();

    const [lastBalanceUpdateTime, setLastBalanceUpdateTime] = useState<number>(
        Date.now() / 1000,
    );

    const handleNotification = (notifyMsg: string) => {
        try {
            if (notifyMsg === "heartbeat") {
                console.log("Received Heart Beat to Keep Live");
                return;
            }

            const messageData = JSON.parse(notifyMsg);

            console.log("Received Notification", messageData);

            if (
                messageData["type"] === "balanceUpdate" &&
                messageData["username"] === auth?.user?.username
            ) {
                // Refreshing after 5 Seconds
                setTimeout(() => {
                    setLastBalanceUpdateTime(Date.now() / 1000);
                }, 5000);
            }
        } catch (error: any) {
            console.log("Notification Handling Failed", notifyMsg);
        }
    };

    useEffect(() => {
        let eventSource: EventSource;

        const startListening = () => {
            const token = getToken();

            if (token) {
                // If a connection is already there closing before reconnect
                if (eventSource) {
                    eventSource.close();
                }

                eventSource = new EventSource(
                    `${process.env.REACT_APP_BACKEND_URL}/notifications/listenToNotifications?token=${token}`,
                );

                eventSource.onopen = () => {
                    console.log("Push Notification connection started");
                };

                eventSource.onmessage = (event) => {
                    handleNotification(event.data);
                };

                eventSource.onerror = (error) => {
                    console.error("Push Notification Listening failed", error);
                    eventSource.close();
                };
            }
        };

        if (auth.isLogged) {
            startListening();
        }

        return () => {
            if (eventSource) {
                console.log("Closing Push Notifications Connection");
                eventSource.close();
            }
        };
    }, [auth.isLogged]);

    return (
        <NotificationContext.Provider value={{ lastBalanceUpdateTime }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
