import { DateTime } from "luxon";
import { PaginationPropsType } from "src/types/paginationTypes";
import Big from "big.js";
import { mainContainerStyle } from "src/components/about/About.style";
import { pageHeightWithoutHeader } from "src/components/SharedStyles";

interface ReturnDateTime {
    date: string;
    time: string;
    fullDateTime: string | null;
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;

export function dateTimeConvertor(
    epochValue: number,
    zone?: string,
): ReturnDateTime {
    const dateTime = zone
        ? DateTime.fromSeconds(epochValue, { zone })
        : DateTime.fromSeconds(epochValue);

    const date = dateTime.toFormat("yyyy-MM-dd");
    const time = dateTime.toFormat("HH:mm:ss");
    const fullDateTime = dateTime.toISO();

    return { date, time, fullDateTime };
}

export function countryCodeToNumber(countryCode: string) {
    const trimmed = countryCode.startsWith("+")
        ? countryCode.substring(1)
        : countryCode;
    if (trimmed.length <= 4) {
        return trimmed.padStart(4, "0");
    } else {
        throw Error("Invalid country code !");
    }
}

export function phoneNumberToCountryCode(phoneNo: string) {
    const code = "+" + phoneNo.slice(0, 4).replace(/^0+/, "");
    const number = phoneNo.slice(4);
    return { code, number };
}

export function getValue(value: string) {
    if (value.match(/^[ ]*$/)) {
        return "";
    } else {
        return value;
    }
}
export const trimSpaces = (data: string) => data.replace(/^\s+|\s+$/g, "");
export const onlyNumbers = (data: string) => data.replace(/[^0-9]/g, "");

export const onlyNumbersWithDot = (data: string) => {
    let result = data.replace(/[^0-9.]/g, "").replace(/\\/g, "");

    const dotIndex = result.indexOf(".");
    if (dotIndex !== -1) {
        result =
            result.substring(0, dotIndex + 1) +
            result.substring(dotIndex + 1).replace(/\./g, "");
    }

    if (result.startsWith(".")) {
        result = "0" + result;
    }
    return result;
};

export const calcPaginationProps = (
    paginationProps: PaginationPropsType,
    count: number | null,
) => ({
    ...paginationProps,
    pages: Math.ceil(count ? count / paginationProps.rowsPerPage : 0),
});

export const getRemainingTime = (sentAtEpoch: number) => {
    const currentTime = DateTime.now();
    const receivedTime = DateTime.fromSeconds(sentAtEpoch);
    const diffInSeconds = currentTime.diff(receivedTime, "seconds").seconds;

    if (diffInSeconds < 60) {
        return "Just now";
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
    } else {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
    }
};

export const upperFirstLetter = (message: string) =>
    message.charAt(0).toUpperCase() + message.slice(1);

export const calculateBidValue = (
    priority: number,
    successFee: number | undefined,
    regFee: number | undefined,
) => +(successFee ?? 1) * +priority + +(regFee ?? 1);

export const calculateBidValueBigInt = (
    priority: number,
    successFee: number | undefined,
    regFee: number | undefined,
) => {
    if (regFee !== undefined && successFee !== undefined) {
        const fee = new Big(successFee);
        return fee.times(priority).plus(regFee);
    } else {
        return new Big(0);
    }
};

export function getCurrentTime() {
    return DateTime.utc().toSeconds();
}

export function calculateDateDiff(date1: any, date2: any) {
    try {
        if (date1 === null || date2 === null) return "00h 00m";

        const date1Formatted = DateTime.fromSeconds(date1);
        const date2Formatted = DateTime.fromSeconds(date2);
        const diff = date1Formatted.diff(date2Formatted, [
            "hour",
            "minute",
            "second",
        ]);

        if (isNaN(diff.minutes)) return "00h 00m";
        if (diff.hours < 0 || diff.minutes < 0 || diff.seconds < 0)
            return "00h 00m";

        const hours = diff.hours.toString().padStart(2, "0");
        const minutes = diff.minutes.toString().padStart(2, "0");
        const date = `${hours}h ${minutes}m`;
        return date;
    } catch (e) {
        return "00h 00m";
    }
}

export function formatTimeRemainingSeconds(endEpoch: number): string {
    const startEpoch = DateTime.now().toSeconds();

    if (startEpoch > endEpoch) return "00 Minutes 00 Seconds";

    const secondDifference = endEpoch - startEpoch;

    const days = Math.floor(secondDifference / (24 * 3600));
    const hours = Math.floor((secondDifference % (24 * 3600)) / 3600);

    return `${days} Days ${hours.toString().padStart(2, "0")} Hours`;
}

export function formatTimeRemaining(endEpoch: number): string {
    try {
        const startEpoch = DateTime.now().toSeconds();

        if (startEpoch > endEpoch) throw "Epoch is already passed";

        let secondDifference = endEpoch - startEpoch;

        const units = [
            { label: "y", seconds: 31536000 },
            { label: "m", seconds: 2592000 },
            { label: "w", seconds: 604800 },
            { label: "d", seconds: 86400 },
            { label: "h", seconds: 3600 },
            { label: "m", seconds: 60 },
            { label: "s", seconds: 1 },
        ];

        const result: string[] = [];

        for (const unit of units) {
            const value = Math.floor(secondDifference / unit.seconds);
            if (result.length === 0 && value > 0) {
                const paddedValue = value.toString().padStart(2, "0");
                result.push(`${paddedValue}${unit.label}`);
                secondDifference %= unit.seconds;
            } else if (result.length === 1 && value >= 0) {
                const paddedValue = value.toString().padStart(2, "0");
                result.push(`${paddedValue}${unit.label}`);
                break;
            }
        }

        return result.join(" ");
    } catch {
        return "00m 00s";
    }
}

export function getMinimumBid(
    currentPrice: number,
    userBid: number,
    stepCount: number,
): number | null {
    if (!stepCount) {
        return null;
    }
    if (currentPrice + stepCount <= userBid) {
        return userBid + stepCount;
    } else {
        return currentPrice + stepCount;
    }
}

export function isTabResolution(windowObj: Window): boolean {
    return (
        windowObj.innerWidth > 600 &&
        windowObj.innerHeight > windowObj.innerWidth
    );
}

export function getAuthPageContainerStyle(windowObj: Window) {
    const isInTab =
        windowObj.innerWidth > 800 &&
        windowObj.innerHeight > windowObj.innerWidth;

    const style = {
        ...mainContainerStyle,
        height: pageHeightWithoutHeader,
        overflowY: "auto",
        justifyContent: "left",
        ...(isInTab && {
            backgroundSize: "auto 50%",
        }),
    };

    return style;
}

export function processPageRequest(
    rawPageReq: number,
    totalPages: number,
): number {
    let processedPageReq = 1;

    const digitWise: string = rawPageReq.toString();

    for (let digitCount = 0; digitCount < digitWise.length; digitCount++) {
        rawPageReq = parseInt(digitWise.slice(digitCount));

        if (rawPageReq >= 1 && rawPageReq <= totalPages) {
            processedPageReq = rawPageReq;
            break;
        }
    }

    return processedPageReq;
}

export const onlyNumericalKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
) => {
    const allowedKeys = /^[0-9.]$/;
    if (event.key === "Backspace" || allowedKeys.test(event.key)) {
        return;
    }
    event.preventDefault();
};
