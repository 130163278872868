import React, { createContext, useState } from "react";
import { messageType, MessageContextType } from "../types/dataContextTypes";
import SnackbarComponent from "src/components/SnackbarComponent";
import { AlertTypes } from "src/enums/contextEnums";

export const MessageContext = createContext<MessageContextType>(
    {} as MessageContextType,
);

export const MessageProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [message, setMessage] = useState<messageType>({
        message: "",
        type: AlertTypes.INFO,
    });
    const [show, setShow] = useState<boolean>(false);

    function showInfo(message: string) {
        setMessage({ message, type: AlertTypes.INFO });
        setShow(true);
    }

    function showError(message: string) {
        setMessage({ message, type: AlertTypes.ERROR });
        setShow(true);
    }

    function showSuccess(message: string) {
        setMessage({ message, type: AlertTypes.SUCCESS });
        setShow(true);
    }

    return (
        <MessageContext.Provider value={{ showError, showSuccess, showInfo }}>
            <SnackbarComponent
                message={message.message}
                open={show}
                isError={message.type === AlertTypes.ERROR}
                handleOnStatusChange={setShow}
                hideDuration={2000}
            />
            {children}
        </MessageContext.Provider>
    );
};
