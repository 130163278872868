import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ConcentricCirclesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="10" fill="currentColor" opacity="0.3" />
            <circle cx="12" cy="12" r="7" fill="currentColor" opacity="0.5" />
            <circle cx="12" cy="12" r="4" fill="currentColor" opacity="1" />
        </SvgIcon>
    );
};

export default ConcentricCirclesIcon;
