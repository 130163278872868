import {
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    SxProps,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Dispatch, SetStateAction, useState } from "react";
import {
    inputLabelStyle,
    styleMeasures,
    selectionStyle,
    menuItemStyle,
    menuPropsStyle,
} from "./multiDropDown.style";
import { upperFirstLetter } from "src/utils/helperFunctions";
const MultiDropDown = ({
    id,
    name,
    label,
    data,
    fullWidth,
    selectedItems,
    setSelectedItems,
    checkIcon,
    containerStyles,
    labelStyles,
    selectStyles,
}: {
    id?: string;
    name?: string;
    label: string;
    data: { label: string; value: string }[];
    fullWidth?: boolean;
    selectedItems: any[];
    setSelectedItems: Dispatch<SetStateAction<any>>;
    checkIcon: boolean;
    labelStyles?: SxProps;
    containerStyles?: SxProps;
    selectStyles?: SxProps;
}) => {
    const [dropdownHandler, setDropdownHandler] = useState(false);

    return (
        <FormControl sx={containerStyles} fullWidth={fullWidth}>
            <InputLabel sx={{ ...inputLabelStyle, ...labelStyles }}>
                {label}
            </InputLabel>
            <Select
                id={id}
                name={name}
                sx={{
                    ...selectionStyle,
                    ...selectStyles,
                    borderBottomLeftRadius: dropdownHandler
                        ? 0
                        : styleMeasures.radius,
                    borderBottomRightRadius: dropdownHandler
                        ? 0
                        : styleMeasures.radius,

                    width: "100%",
                }}
                inputProps={{
                    style: {
                        margin: 0,
                        padding: 0,
                    },
                }}
                onOpen={() => {
                    setDropdownHandler(true);
                }}
                onClose={() => {
                    setDropdownHandler(false);
                }}
                multiple
                value={selectedItems}
                onChange={(e) => setSelectedItems(e.target.value)}
                input={
                    <OutlinedInput
                        label={label}
                        sx={{ borderRadius: "30px !important" }}
                    />
                }
                MenuProps={menuPropsStyle}
                renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((value) => (
                            <Chip
                                sx={{ textTransform: "none" }}
                                key={value}
                                label={upperFirstLetter(value)}
                                onDelete={() =>
                                    setSelectedItems(
                                        selectedItems.filter(
                                            (item) => item !== value,
                                        ),
                                    )
                                }
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) =>
                                            event.stopPropagation()
                                        }
                                    />
                                }
                            />
                        ))}
                    </Stack>
                )}
            >
                {data.map((item) => (
                    <MenuItem
                        key={item.label}
                        value={item.value}
                        sx={menuItemStyle}
                    >
                        {item.label}
                        {checkIcon && selectedItems.includes(item.value) ? (
                            <CheckIcon color="info" />
                        ) : null}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiDropDown;
