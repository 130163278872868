export enum LogLevels {
    WARN = "warning",
    ERROR = "error",
    SUCCESS = "success",
    INFO = "info",
}

export enum ErrorSources {
    FS = "fastspring",
    BACKEND = "backend",
}

export enum LogCategories {
    PAYMENT = "payment_logs",
}
