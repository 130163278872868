import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {
    darkYellow,
    lightYellow,
    topUpChipStyle,
    topUpChipText,
} from "src/pages/balancePage/BalancePage.style";

type TopUpChipButtonType = {
    children: number;
    activeValue: number;
    setActiveChipButton: Dispatch<SetStateAction<number>>;
};

const TopUpChipButton = ({
    children,
    activeValue,
    setActiveChipButton,
}: TopUpChipButtonType) => {
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            onClick={() => {
                setActiveChipButton(children);
            }}
            sx={{
                ...topUpChipStyle,
                bgcolor: activeValue === children ? darkYellow : lightYellow,
            }}
        >
            <Typography sx={topUpChipText}>$ {children}</Typography>
        </Box>
    );
};

export default TopUpChipButton;
