import { addDoc, collection } from "firebase/firestore";
import { logBase } from "./firebaseConfig";
import { LogCategories } from "src/enums/loggerEnums";
import { PaymentLoggerType } from "src/types/loggerTypes";
import { DateTime } from "luxon";

async function log(database: LogCategories, data: any) {
    try {
        const timestamp = DateTime.now().toString();
        const logRef = await addDoc(collection(logBase, database), {
            ...data,
            timestamp,
        });
        console.log(logRef);
    } catch (error) {
        console.error("Logging error");
    }
}

export async function paymentLog(data: PaymentLoggerType) {
    log(LogCategories.PAYMENT, data);
}

export async function paymentErrorLog(data: any) {
    log(LogCategories.PAYMENT, data);
}
