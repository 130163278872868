export const stackStyle = {
    justifyContent: "space-between",
    width: "inherit",
    transition: "all 0.3s",
};

export const typographyStyle = {
    fontSize: "14px",
    textAlign: "justify",
};

export const iconWrapperStyle = {
    pl: 2,
    py: "5px",
    display: "flex",
    alignItems: "flex-start",
};
