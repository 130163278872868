import React from "react";
import useAuth from "src/hooks/useAuth";
import RegistrantProfileForm from "../../forms/RegistrantProfileForm";

const RegistrantProfilePage = () => {
    const auth = useAuth();
    if (
        (auth && auth.user?.isCompleted) ||
        sessionStorage.getItem("isCompleted") == "true"
    ) {
        return <RegistrantProfileForm update />;
    } else {
        return <RegistrantProfileForm />;
    }
};

export default RegistrantProfilePage;
