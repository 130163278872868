import { Alert, Snackbar } from "@mui/material";

type SnackbarProps = {
    message: string;
    open: boolean;
    isError: boolean;
    handleOnStatusChange: (value: boolean) => void;
    hideDuration: number;
};

function SnackbarComponent(props: SnackbarProps) {
    const { handleOnStatusChange } = props;
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }
        handleOnStatusChange(false);
    };

    const alertStyle = {
        width: "100%",
        backgroundColor: props.isError ? "#FEB3B1" : "#D8FFC6",
        color: props.isError ? "#FF2C2E" : "#3BB800",
        borderRadius: "15px",
        fontWeight: 600,
    };

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={props.hideDuration}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            sx={{
                "&.MuiSnackbar-root": {
                    top: "95px",
                },
            }}
        >
            <Alert onClose={handleClose} icon={false} sx={alertStyle}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarComponent;
