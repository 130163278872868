import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, SxProps } from "@mui/material";
import { useState } from "react";

type PasswordFieldProps = {
    formValueName: string;
    form: any;
    sx?: SxProps;
};

function PasswordField({ formValueName, form, sx }: PasswordFieldProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextField
            fullWidth
            id={`password_${formValueName}`}
            name={formValueName}
            autoComplete="off"
            sx={sx}
            value={form.values[formValueName]}
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={(event) =>
                form.setFieldValue(formValueName, event.target.value.trim())
            }
            error={
                form.touched[formValueName] &&
                Boolean(form.errors[formValueName])
            }
            helperText={
                form.touched[formValueName] && form.errors[formValueName]
            }
        />
    );
}

export default PasswordField;
