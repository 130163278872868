import { Button } from "@mui/material";
import React from "react";

const PopupButton = ({
    type,
    triggerFunction,
    children,
    height,
    id,
    styleProps,
    disabled = false,
}: {
    type: "cancel" | "proceed";
    triggerFunction: () => void;
    children: string;
    height?: string;
    id?: string;
    styleProps?: any;
    disabled?: boolean;
}) => {
    const styles = {
        proceed: {
            px: { md: 2, xs: 2 },
            py: { xs: 1, md: "11px" },
            bgcolor: "#EFD016",
            color: "#3A3541CC",
            fontSize: "14px",
            fontWeight: 500,
            alignSelf: { md: "center", xs: "flex-end" },
            my: { xs: 2, md: 0 },
            textTransform: "none",
            mx: 1,
            "&:hover": {
                bgcolor: "#F1D631",
            },
            borderRadius: 5,
            mb: 0,
            width: "100px",
            height: height ? height : 30,
            ml: 1,
            ...styleProps,
        },
        cancel: {
            px: { md: 2, xs: 2 },
            py: { xs: 1, md: "11px" },
            bgcolor: "white",
            color: "#3A3541CC",
            fontSize: 14,
            fontWeight: 500,
            alignSelf: { md: "center", xs: "flex-end" },
            my: { xs: 2, md: 0 },
            textTransform: "none",
            mx: 1,
            borderRadius: 5,
            mb: 0,
            width: "100px",
            height: height ? height : 30,
            border: 2,
            borderColor: "#3A3541CC",
            mr: 1,
            "&:hover": {
                borderColor: "#EFD016",
                bgcolor: "#000",
                color: "#EFD016",
            },
            ...styleProps,
        },
    };
    return (
        <Button
            disabled={disabled}
            sx={styles[type]}
            onClick={triggerFunction}
            id={id}
        >
            {children}
        </Button>
    );
};

export default PopupButton;
