export const regProfileStyles = {
    checkboxStyle: {
        border: "1px",
        fontSize: "18px",
        margin: 0,
        "&.Mui-checked": {
            color: "#21B2E7",
        },
        "& .MuiSvgIcon-fontSizeMedium": {
            stroke: "azure",
        },
    },
    buttonStyle: {
        color: "#3A3541CC",
        px: 4,
        textTransform: "none",
        bgcolor: "#EFD016",
        "&:hover": {
            bgcolor: "#FFEA6B",
        },
        borderRadius: "15px",
        fontWeight: 500,
    },
    inputBox: { display: "flex", alignItems: "center", borderRadius: "15px" },
    sectionHeading: {
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        LineHeight: "18px",
        fontWeight: 500,
        color: "#3A3541CC",
    },
    textField: {
        fontSize: "16px",
        "& .MuiInputBase-formControl": {
            borderRadius: 4,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3A354180",
        },
    },
    inputWrapper: {
        label: {
            fontSize: "14px",
            pb: 1,
            color: "#3A3541CC",
        },
        errorMsg: {
            fontSize: "13px",
            color: "#FF4D4F",
        },
    },
};

export const checkboxContainerStyle = {
    width: { xs: "100%", sm: "45%", md: "25%", lg: "12%" },
    paddingTop: 2,
};
