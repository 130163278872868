import { Box, Divider, Typography } from "@mui/material";

const HeaderWithSeparator = ({
    label,
    labelStyle,
}: {
    label: string;
    labelStyle: any;
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography
                sx={{
                    flexShrink: 0,
                    marginRight: 1,
                    ...labelStyle,
                }}
            >
                {label}
            </Typography>
            <Divider
                sx={{
                    flexGrow: 1,
                    marginLeft: 1,
                    borderBottomWidth: 1,
                    borderColor: "#ECEFF2",
                }}
            />
        </Box>
    );
};

export default HeaderWithSeparator;
