import { Tab, Tabs } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

const TabSet = ({
    currentTab,
    setCurrentTab,
    data,
}: {
    currentTab: string;
    setCurrentTab: Dispatch<SetStateAction<string>>;
    data: { label: string; value: string }[];
}) => {
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };
    return (
        <Tabs
            value={currentTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{
                "& .MuiTabs-indicator": {
                    backgroundColor: "#EFD016",
                },
            }}
        >
            {data.map((tab) => (
                <Tab
                    value={tab.value}
                    key={tab.value}
                    label={tab.label}
                    sx={{
                        "&.Mui-selected": {
                            color: "#000",
                            fontWeight: 500,
                        },
                        textTransform: "none",
                        paddingTop: 0,
                        paddingX: { xs: "5px", sm: "16px" },
                        fontSize: { xs: "12px", sm: "15px" },
                    }}
                />
            ))}
        </Tabs>
    );
};

export default TabSet;
