import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { dateTimeConvertor } from "src/utils/helperFunctions";
import EmptyTable from "../table/EmptyTable";
import useData from "src/hooks/useApi";
import {
    HeadingType,
    OrderPropsType,
    TableData,
    data,
} from "src/types/submissionsTypes";
import SubmissionsHeadRow from "./SubmissionsPageHeadRow";
import PopupButton from "../common/PopupButton";
import useMessage from "src/hooks/useMessage";
import StatusChip from "../common/SubmissionStatusChip";
import { SubmissionStatusEnum } from "src/enums/submissionsPageEnum";
import { DateTime } from "luxon";
import LoadingTable from "../table/LoadingTable";
import { StyledTableCell } from "../SharedStyles";

const headRow: HeadingType[] = [
    {
        id: "domain",
        label: "Domain",
        align: "left",
        sorting: true,
    },
    {
        id: "date",
        label: "Added Time (UTC)",
        align: "center",
        sorting: true,
    },
    {
        id: "status",
        label: "Status",
        align: "center",
        sorting: true,
    },
    {
        id: "message",
        label: "Message",
        align: "center",
        sorting: true,
    },
    {
        id: "id",
        label: "Actions",
        align: "center",
    },
];

const SubmissionsTable = ({
    apiData,
    orderProps,
    setOrderProps,
    setResubmitTrigger,
    setEndDate,
    loading,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    setResubmitTrigger: Dispatch<SetStateAction<boolean>>;
    setEndDate: Dispatch<SetStateAction<DateTime<true>>>;
    loading?: boolean;
}) => {
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<TableData[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const { postData } = useData();
    const { showError } = useMessage();

    useEffect(() => {
        const convertedData: TableData[] = apiData.map((item) => ({
            ...item,
            date: item.date
                ? `${dateTimeConvertor(+item.date).date} ${
                      dateTimeConvertor(+item.date).time
                  }`
                : "",
        }));
        setRows(convertedData);
    }, [apiData]);

    async function resubmitBid(row: TableData) {
        try {
            await postData("/bid/retryHqInform", {
                submissionId: row.id,
            });
            // showSuccess(`${row.domain} Resubmitted Successfully`);
            setEndDate(DateTime.now().plus({ hours: 1 }));
        } catch (error) {
            showError("Error, please retry adding the bid");
        }
        setResubmitTrigger((resubmitTrigger) => !resubmitTrigger);
    }

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <SubmissionsHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={headRow}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={headRow.length} />
                    ) : apiData.length > 0 ? (
                        rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {row.domain}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.date}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <StatusChip type={row.status} />
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.message}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    {row.status ===
                                        SubmissionStatusEnum.FAILURE &&
                                        row.attemptCount != 0 && (
                                            <PopupButton
                                                type="proceed"
                                                id="resubmit-button"
                                                triggerFunction={() => {
                                                    resubmitBid(row);
                                                }}
                                                height="35px"
                                                styleProps={{
                                                    fontSize: 12,
                                                    px: { md: 2, xs: 1 },
                                                    fontWeight: 500,
                                                    "&&:disabled": {
                                                        bgcolor: "#D8D7D9",
                                                    },
                                                }}
                                                disabled={
                                                    row.status ===
                                                        SubmissionStatusEnum.FAILURE &&
                                                    row.attemptCount === 4
                                                }
                                            >
                                                Resubmit
                                            </PopupButton>
                                        )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable
                            msg="No submissions"
                            colSpan={headRow.length}
                        />
                    )}
                </TableBody>
            </Table>
            {false && (
                <TablePagination
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e: unknown, newPage: number) => {
                        setPage(newPage);
                    }}
                    onRowsPerPageChange={(e: any) => {
                        setRowsPerPage(e.target.value);
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            )}
        </TableContainer>
    );
};

export default SubmissionsTable;
