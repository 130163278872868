import {
    Box,
    Chip,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EmptyTable from "./EmptyTable";
import LoadingTable from "./LoadingTable";
import Big from "big.js";
import { tableHead } from "./tableStyles";
import { auctionTableHeader } from "src/pages/auctionsPage/auctionsPage.style";
import { StyledTableCell } from "../SharedStyles";

export interface AuctionData {
    key: number;
    bidder: string;
    date: number | string;
    amount: Big.Big;
    isMyBid: boolean;
    isHighestBid: boolean;
}

export interface UserBidInfo {
    amount: Big.Big;
    isHighestBid: boolean;
    isPaymentPending: boolean;
}

const bidChipStyle = {
    position: "absolute",
    left: "120%",
    height: "23px",
    minWidth: "63px",
    fontSize: "10px",
    borderColor: "#3bb800",
    color: "#3bb800",
    display: { xs: "none", md: "flex" },
};

const highBidContainer = {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
};

const AuctionsTable = ({
    auctionData,
    loading,
}: {
    auctionData: AuctionData[];
    loading?: boolean;
}) => {
    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            "& .MuiTableCell-head": {
                                position: "relative",
                            },
                            backgroundColor: "red",
                        }}
                    >
                        <StyledTableCell size="medium" key={"bid"}>
                            <Typography sx={tableHead.headStyle}>
                                {"BID"}
                            </Typography>
                            <Box sx={auctionTableHeader.divider}></Box>
                        </StyledTableCell>
                        <StyledTableCell
                            size="medium"
                            key={"amount"}
                            align="center"
                        >
                            <Typography sx={tableHead.headStyle}>
                                {"Amount"}
                            </Typography>
                            <Box sx={auctionTableHeader.divider}></Box>
                        </StyledTableCell>
                        <StyledTableCell
                            size="medium"
                            key={"date"}
                            align="center"
                        >
                            <Typography sx={tableHead.headStyle}>
                                {"Date"}
                            </Typography>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={3} />
                    ) : auctionData.length > 0 ? (
                        auctionData.map((auctionBid) => (
                            <StyledTableRow key={auctionBid.key}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {auctionBid.bidder}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    <Box sx={highBidContainer}>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                color: auctionBid.isMyBid
                                                    ? auctionBid.isHighestBid
                                                        ? "#21B2E7"
                                                        : "#ff4d4f"
                                                    : undefined,
                                            }}
                                        >
                                            {auctionBid.amount.toFixed(2)}
                                        </Typography>
                                        {auctionBid.isHighestBid && (
                                            <Chip
                                                label="High Bid"
                                                color="success"
                                                variant="outlined"
                                                sx={bidChipStyle}
                                            />
                                        )}
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {auctionBid.date}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable msg="No data" colSpan={3} />
                    )}
                </TableBody>
            </Table>
            {/* Removed the Non Rendering Pagination Section from here */}
        </TableContainer>
    );
};

export default AuctionsTable;
