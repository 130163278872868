import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const CurrentRouteContext = createContext<any>(null);

export const excludeArray = [
    "/login",
    "/register",
    "/forgotPassword",
    "/changePassword",
    "/",
];
export const defaultRoute = "/dashboard/preBids";

export const CurrentRouteProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
    const location = useLocation();
    useEffect(() => {
        setCurrentRoute(location.pathname);
        if (!excludeArray.includes(location.pathname))
            localStorage.setItem("currentRoute", location.pathname);
        else localStorage.setItem("currentRoute", defaultRoute);
    }, [location]);

    return (
        <CurrentRouteContext.Provider value={{ currentRoute }}>
            {children}
        </CurrentRouteContext.Provider>
    );
};

export default CurrentRouteContext;
