import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DateRangePicker from "src/components/common/DateRangePicker";
import { DateTime } from "luxon";
import {
    buttonItem,
    containerStyle,
    datePickerItem,
    filterItem,
    multipleSelectItem,
    searchItem,
} from "./PreBidsStyle";
import MultiDropDown from "src/components/common/MultiDropDown";
import { BsPlusLg } from "react-icons/bs";
import ActionButton from "src/components/common/ActionButton";
import { OrderTypes } from "src/enums/sortingsEnum";
import { calcPaginationProps } from "src/utils/helperFunctions";
import { PaginationPropsType } from "src/types/paginationTypes";
import { OrderPropsType, data } from "src/types/allBidsPageTypes";
import { payloadType } from "src/types/dataFetchingTypes";
import useData from "src/hooks/useApi";
import useMessage from "src/hooks/useMessage";
import TablePagination from "src/components/table/TablePagination";
import SearchField from "src/components/common/SearchField";
import UpdateModel from "src/components/allBids/UpdateModel";
import DeleteModel from "src/components/allBids/DeleteModel";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import DomainsInputModel from "src/components/allBids/DomainsInputModel";
import AllBidsResponseModel from "src/components/allBids/AllBidsResponseModel";
import SelectPriorityModel from "src/components/allBids/SelectPriorityModel";
import {
    PreAuctionEnum,
    ProgressStatusEnum,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import { backorderStatusSelections } from "../../data/bidsStatusFilter";
import PreBidsPageTable from "src/components/allBids/PreBidsPageTable";
import BidTopUp from "../../components/balance/BidTopUp";
import useNotification from "src/hooks/useNotification";

const PreBidsPage = ({ currentTab }: { currentTab: string }) => {
    // Hooks
    const api = useData();
    const { showError } = useMessage();
    const { popupState } = useBidsPopupState();
    const lastBalanceUpdateTime = useNotification();

    // Filters and searches
    const [endDate, setEndDate] = useState<DateTime>();
    const [startDate, setStartDate] = useState<DateTime>();
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // common
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [tableData, setTableData] = useState<data[]>([]);
    const [orderProps, setOrderProps] = useState<OrderPropsType>({
        order: OrderTypes.ASC,
        orderBy: "dropTime",
    });

    // Popup states
    const [updatePopup, setUpdatePopup] = useState<boolean>(false);
    const [topUpPopUp, setTopUpPopUp] = useState<boolean>(false);
    const [defaultAmount, setDefaultAmount] = useState<number>(20);
    const [message, setMessage] = useState<string>("");
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [domainsInputPopup, setDomainsInputPopup] = useState<boolean>(false);
    const [selectPriorityPopup, setsSelectPriorityPopup] =
        useState<boolean>(false);
    const [responsePopup, setResponsePopup] = useState<boolean>(false);

    const [availBids, setAvailBids] = useState<number>(0);
    const { fetchData } = useData();

    // State to store isCompleted from sessionStorage
    const [isCompleted, setIsCompleted] = useState<boolean | null>(null);

    async function getData(isReset: boolean = false) {
        try {
            setLoading(true);
            const payload: payloadType = {
                page: paginationProps.currentPage,
                size: paginationProps.rowsPerPage,
                filterAnd: [],
                filterOr: [],
                sort: {
                    key: orderProps.orderBy,
                    order: orderProps.order.toUpperCase(),
                },
            };
            if (isReset) {
                payload.page = 1;
                setPaginationProps((paginationProps) => ({
                    ...paginationProps,
                    currentPage: 1,
                }));
            }

            if (selectedStatus.length > 0) {
                payload.filterAnd = [];
                const statusSet: string[] = [];
                console.log(selectedStatus);
                if (selectedStatus.includes(PreAuctionEnum.PAYMENT)) {
                    statusSet.push(ProgressStatusEnum.SUCCESS);
                }
                if (selectedStatus.includes(PreAuctionEnum.INPROGRESS)) {
                    statusSet.push(ProgressStatusEnum.WAITING);
                    statusSet.push(ProgressStatusEnum.INPROGRESS);
                }
                if (selectedStatus.includes(UserStatusEnum.SUBMISSIONPEND)) {
                    statusSet.push(ProgressStatusEnum.PENDING);
                }
                if (selectedStatus.includes(UserStatusEnum.RESULT_PENDING)) {
                    statusSet.push(ProgressStatusEnum.RESULT_PENDING);
                }
                payload.filterAnd.push({
                    // eslint-disable-next-line quotes
                    key: "status",
                    operation: "IN",
                    value: [...statusSet],
                });
            }

            if (searchValue.trim().length > 0 && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "domainName",
                    operation: "LIKE",
                    value: [`${searchValue.trim()}%`],
                });
            }

            if (startDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "dropTime",
                    operation: ">",
                    value: Math.round(startDate.toSeconds()),
                });
            }

            if (endDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "dropTime",
                    operation: "<",
                    value: Math.round(endDate.toSeconds()),
                });
            }

            const response: any = await api?.postData(
                "/bid/getBackorders",
                payload,
            );

            if (response.data) {
                setTableData(response.data);
            } else {
                setTableData([]);
            }
            setPaginationProps((paginationProps) =>
                calcPaginationProps(paginationProps, response.count),
            );
        } catch (error) {
            setTableData([]);
            showError("Data fetching error");
        } finally {
            setLoading(false);
        }
    }

    function bidUpdateAction() {
        if (!isCompleted) {
            showError("Please complete the registrant profile");
        } else {
            setUpdatePopup(true);
        }
    }

    function bidDeleteAction() {
        if (!isCompleted) {
            showError("Please complete the registrant profile");
        } else {
            setDeletePopup(true);
        }
    }

    function bidTopUpAction(message: string, defaultAmount: number) {
        setDefaultAmount(defaultAmount);
        setMessage(message);
        setTopUpPopUp(true);
    }

    const handleChangeSearch = (e: any) => {
        setSearchValue(e.target.value.trim().toLowerCase());
    };

    const openAddDialogBox = () => {
        if (!isCompleted) {
            showError("Please complete the registrant profile");
        } else if (availBids <= 0) {
            showError("You have already exceeded the daily domain limit");
        } else {
            setDomainsInputPopup(true);
        }
    };

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        orderProps.orderBy,
        orderProps.order,
        selectedStatus,
        endDate,
        startDate,
        paginationProps.rowsPerPage,
        currentTab,
        lastBalanceUpdateTime,
    ]);

    // To Clear the Filter on Search Field Clear
    useEffect(() => {
        if (!searchValue) getData(true);
    }, [searchValue]);

    useEffect(() => {
        if (tableData.length <= 0 && paginationProps.currentPage !== 1) {
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }
    }, [tableData]);

    async function getDomainsCount() {
        try {
            const response: any = await fetchData("/bid/availableBidCount");
            if (response <= 0) {
                setAvailBids(0);
            }
            setAvailBids(response);
        } catch (error) {
            showError("Failed to fetch available bid amount");
        }
    }

    useEffect(() => {
        getDomainsCount();
        const storedIsCompleted = sessionStorage.getItem("isCompleted");
        if (storedIsCompleted) {
            setIsCompleted(storedIsCompleted === "true");
        }
    }, []);

    return (
        <Box alignItems="center" sx={containerStyle}>
            <UpdateModel
                setOpen={setUpdatePopup}
                open={updatePopup}
                getData={getData}
            />
            <BidTopUp
                open={topUpPopUp}
                setOpen={setTopUpPopUp}
                message={message}
                defaultAmount={defaultAmount}
            />
            <DeleteModel
                setOpen={setDeletePopup}
                open={deletePopup}
                getData={getData}
                domain={popupState.domain}
            />
            <DomainsInputModel
                setOpen={setDomainsInputPopup}
                open={domainsInputPopup}
                setNextState={setsSelectPriorityPopup}
                availBidsCount={availBids}
            />
            <SelectPriorityModel
                setOpen={setsSelectPriorityPopup}
                open={selectPriorityPopup}
                setNextState={setResponsePopup}
            />
            <AllBidsResponseModel
                setOpen={setResponsePopup}
                open={responsePopup}
            />
            <Grid
                container
                justifyContent={"space-between"}
                paddingBottom={containerStyle.paddingTop}
                rowSpacing={2}
            >
                <Grid item {...filterItem}>
                    <Grid container rowSpacing={2.5} columnSpacing={2}>
                        <Grid item {...searchItem}>
                            <SearchField
                                iconPosition="start"
                                onChange={handleChangeSearch}
                                placeholder="Search Domain"
                                value={searchValue}
                                id="Search-button-all-bids"
                                keyPressTrigger={(e) => {
                                    if (
                                        e.code === "Enter" ||
                                        e.key === "Enter"
                                    ) {
                                        getData(true);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item {...multipleSelectItem}>
                            <MultiDropDown
                                id="all-bids-status-dropdown"
                                label="Progress Status"
                                checkIcon={false}
                                setSelectedItems={setSelectedStatus}
                                selectedItems={selectedStatus}
                                data={backorderStatusSelections}
                                containerStyles={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item {...datePickerItem}>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                startDateLabel={"Drop Time"}
                                endDateLabel={"Drop Time"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item {...buttonItem}>
                    <ActionButton
                        icon={<BsPlusLg />}
                        iconPosition="start"
                        label="Backorder Domains"
                        triggerFunction={openAddDialogBox}
                        style={{
                            width: { xs: "100%", sm: "190px" },
                            height: "45px",
                        }}
                    />
                </Grid>
            </Grid>
            <PreBidsPageTable
                apiData={tableData}
                orderProps={orderProps}
                setOrderProps={setOrderProps}
                popupHandlers={{
                    update: bidUpdateAction,
                    delete: bidDeleteAction,
                    topUp: bidTopUpAction,
                }}
                loading={loading}
            />
            <TablePagination
                paginationProps={paginationProps}
                setPaginationProps={setPaginationProps}
            />
        </Box>
    );
};

export default PreBidsPage;
