import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { contentFontSize } from "./SharedStyles";

const questionStyle = {
    fontSize: { xs: "12px", sm: "15px" },
    fontWeight: 600,
    flexGrow: 1,
    paddingX: "40px",
};

type propsType = {
    question: string;
    message: string;
};

function ExpandableComponent(props: propsType) {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Accordion
            square={true}
            sx={{ boxShadow: 0 }}
            expanded={expanded}
            onChange={() => setExpanded((prevState) => !prevState)}
        >
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                    height: { sm: "80px" },
                    minHeight: { sm: "80px" },
                }}
            >
                <HelpOutlineIcon sx={{ color: "#FFB700" }} />
                <Typography sx={questionStyle}>{props.question}</Typography>
                {expanded ? (
                    <ExpandLessIcon fontSize="medium" />
                ) : (
                    <ExpandMoreIcon fontSize="medium" />
                )}
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
                <Typography
                    sx={{
                        fontSize: contentFontSize,
                        paddingX: "64px",
                    }}
                >
                    {props.message}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}

export default ExpandableComponent;
