export const filterStyles = {
    container: {
        width: "100%",
        mb: { md: 3, sm: 0 },
        display: "flex",
        flexDirection: { md: "row", xs: "column-reverse" },
        alignItems: "space-between",
    },
    datePickerStyle: {
        "& .MuiOutlinedInput-input": {
            py: "12px",
            fontSize: "14px",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 20,
        },
        "& .MuiIconButton-edgeEnd > svg": {
            fontSize: 18,
        },
        "& .MuiIconButton-edgeEnd": {
            mr: 0,
        },
    },
};

export const containerStyle = {
    paddingTop: { xs: 2, sm: 3 },
    display: "flex",
    flexDirection: { xs: "column" },
    borderRadius: 1,
    bgcolor: "#FFFFFF",
};

export const searchItem = {
    xs: 12,
    lg: 3,
};

export const multipleSelectItem = {
    xs: 12,
    md: 6,
    lg: 3,
};

export const datePickerItem = {
    xs: 12,
    md: 6,
    lg: 4,
};

export const buttonItem = {
    xs: 12,
    lg: 2,
    display: "flex",
    justifyContent: "flex-end",
};

export const filterItem = { xs: 12, lg: 10 };
