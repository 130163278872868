import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Dispatch, SetStateAction } from "react";
import TextInputWrapper from "src/components/registrantProfile/ProfileTextInputWrapper";
import { NameserverInputSectionType } from "src/types/registrantProfileTypes";
import { nameserverRegex } from "src/utils/registrantProfileData";
import { regProfileStyles } from "./registrantProfileStyles";
import { getValue, trimSpaces } from "src/utils/helperFunctions";

const InputSection = ({
    data,
    setData,
    errors,
    setErrors,
    errorSwitch,
    tag,
    disabled = false,
}: {
    data: NameserverInputSectionType;
    setData: Dispatch<SetStateAction<NameserverInputSectionType>>;
    errors: NameserverInputSectionType;
    errorSwitch: any;
    setErrors: Dispatch<SetStateAction<NameserverInputSectionType>>;
    tag: string;
    disabled?: boolean;
}) => {
    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            paddingTop={2}
            paddingBottom={2}
        >
            <Grid sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.nameserver1 !== ""}
                    label="Nameserver 1"
                    warning={errors.nameserver1}
                >
                    <TextField
                        id="nameserver1"
                        type="text"
                        onChange={(e) => {
                            const value = trimSpaces(getValue(e.target.value));
                            setData((data) => ({
                                ...data,
                                nameserver1: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    nameserver1: "Required Field",
                                }));
                            else {
                                if (!nameserverRegex.test(value))
                                    setErrors((errors) => ({
                                        ...errors,
                                        nameserver1: "Invalid Nameserver",
                                    }));
                                else {
                                    if (value === data.nameserver2) {
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver2: "Duplicate Nameserver",
                                        }));
                                    } else {
                                        if (
                                            errors.nameserver2 ===
                                            "Duplicate Nameserver"
                                        ) {
                                            setErrors((errors) => ({
                                                ...errors,
                                                nameserver2: "",
                                            }));
                                        }
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver1: "",
                                        }));
                                    }
                                }
                            }
                        }}
                        value={data.nameserver1}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.nameserver2 !== ""}
                    label="Nameserver 2"
                    warning={errors.nameserver2}
                >
                    <TextField
                        id="nameserver2"
                        name="nameserver2"
                        onChange={(e) => {
                            const value = trimSpaces(getValue(e.target.value));
                            setData((data) => ({
                                ...data,
                                nameserver2: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    nameserver2: "Required Field",
                                }));
                            else {
                                if (!nameserverRegex.test(value))
                                    setErrors((errors) => ({
                                        ...errors,
                                        nameserver2: "Invalid Nameserver",
                                    }));
                                else {
                                    if (value === data.nameserver1) {
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver2: "Duplicate Nameserver",
                                        }));
                                    } else {
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver2: "",
                                        }));
                                    }
                                }
                            }
                        }}
                        value={data.nameserver2}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
        </Grid>
    );
};

export default InputSection;
