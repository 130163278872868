import { Dispatch, SetStateAction } from "react";
import { Badge, Box } from "@mui/material";
import { BsBell } from "react-icons/bs";

const NotificationButton = ({
    setOpen,
    unreadList,
    disabled,
}: {
    setOpen: Dispatch<SetStateAction<boolean>>;
    unreadList: number[];
    disabled: boolean;
}) => {
    const BadgeStyles = {
        wrapper: {
            display: "flex",
            alignItems: "center",
            mx: 2,
            cursor: "pointer",
            "& .MuiBadge-badge": {
                fontSize: 10,
                minWidth: 0,
                width: 0,
                height: 0,
                padding: "8px",
                top: 4,
                right: 4,
                bgcolor: "#FF4D4F",
            },
        },
    };

    return (
        <Box
            sx={BadgeStyles.wrapper}
            onClick={() => {
                if (!disabled) {
                    setOpen((open) => !open);
                }
            }}
        >
            <Badge badgeContent={unreadList.length} color="error">
                <BsBell style={{ color: "#EFD016", fontSize: "23px" }} />
            </Badge>
        </Box>
    );
};

export default NotificationButton;
