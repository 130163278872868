import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import { Stack, TextField, Typography } from "@mui/material";
import PopupButton from "../common/PopupButton";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import useMessage from "../../hooks/useMessage";

const DomainsInputModel = ({
    open,
    setOpen,
    setNextState,
    availBidsCount,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setNextState: Dispatch<SetStateAction<boolean>>;
    availBidsCount: number;
}) => {
    const [domains, setDomains] = useState<string>("");
    const [domainsA, setDomainsA] = useState<string[]>([]);
    const handleClose = () => {
        setErrorState({
            touched: false,
            message: "Required Field",
            error: false,
        });
        if (!domains.trim()) {
            setDomains("");
        }
        setOpen(false);
    };
    const { showError } = useMessage();
    const [errorState, setErrorState] = useState({
        touched: false,
        error: false,
        message: "",
    });
    const { setupDomainList, loading } = useBidsPopupState();

    async function addDomainsToPopup() {
        console.log(domains);
        if (domains.trim()) {
            if (await setupDomainList(domains)) {
                handleClose();
                setDomains("");
                setNextState(true);
            } else {
                showError("Data fetching error");
            }
        } else {
            setErrorState({
                touched: true,
                message: "Required Field",
                error: true,
            });
        }
    }

    function handleChange(e: any) {
        const value = e.target.value.toLowerCase();
        const domainsArray = value
            .split("\n")
            .filter((domain: string) => domain.trim() !== "");

        setDomains(value);
        setDomainsA(domainsArray);

        if (domainsArray.length / availBidsCount > 1) {
            setErrorState({
                touched: errorState.touched,
                message: "Cannot exceed the daily domains limit",
                error: true,
            });
        } else if (
            e.target.value.trim() === "" ||
            e.target.value.trim() === undefined
        ) {
            setErrorState({
                touched: errorState.touched,
                message: "Required Field",
                error: true,
            });
        } else
            setErrorState({
                touched: errorState.touched,
                message: "",
                error: false,
            });
    }

    useEffect(() => {
        if (open) {
            setDomainsA([]);
            setDomains("");
            setErrorState({
                touched: false,
                message: "",
                error: false,
            });
        }
    }, [open]);

    return (
        <PopupModelWrapper
            open={open}
            handleClose={handleClose}
            title="Add Domains"
        >
            <Stack>
                <Typography align={"right"} padding={1}>
                    {domainsA.length + "/" + availBidsCount}
                </Typography>
                <TextField
                    id="domains-input-text-field"
                    variant="outlined"
                    multiline
                    hiddenLabel
                    placeholder="mydomain1.com&#10;mydomain2.com , 2&#10;mydomain3.com , 1"
                    sx={{
                        fontSize: "10px",
                        m: 2,
                        "& .MuiOutlinedInput-input": {
                            py: 0,
                            fontSize: "14px",
                        },
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 5,
                            borderWidth: 0.5,
                        },
                    }}
                    rows={4}
                    value={domains}
                    onChange={handleChange}
                    error={errorState.error}
                    helperText={errorState.error && errorState.message}
                />
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mb: 1, mx: 1 }}
                >
                    <PopupButton
                        triggerFunction={handleClose}
                        height="40px"
                        type="cancel"
                        id="domains-input-popup-cancel"
                    >
                        Cancel
                    </PopupButton>
                    <PopupButton
                        triggerFunction={addDomainsToPopup}
                        type="proceed"
                        height="40px"
                        id="domains-input-popup-add"
                        disabled={loading || errorState.error}
                    >
                        Add
                    </PopupButton>
                </Stack>
            </Stack>
        </PopupModelWrapper>
    );
};

export default DomainsInputModel;
