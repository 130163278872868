import { Suspense } from "react";
import LandingHeader from "../navigation/LandingHeader";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { Grid } from "@mui/material";
import { contentWithoutAuthStyle, headerContainerStyle } from "../SharedStyles";

export const Layout = ({ showFooter }: { showFooter: boolean }) => (
    <Grid
        container
        style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
        }}
    >
        <Grid item xs={12} sx={headerContainerStyle}>
            <LandingHeader />
        </Grid>
        <Grid item xs={12} sx={contentWithoutAuthStyle}>
            <Suspense>
                <Outlet />
            </Suspense>
        </Grid>
        {showFooter && (
            <Grid item xs={12}>
                <Footer />
            </Grid>
        )}
    </Grid>
);
