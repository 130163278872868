import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
    cardContentBodyStyle,
    cardContentHeaderStyle,
    cardContentStyle,
    cardMediaStyle,
    cardStyle,
    gridItemStyle,
} from "./FeatureCard.style";
import { Grid } from "@mui/material";

type propsType = {
    image: string;
    header: string;
    content: string;
    imagePosition: 0 | 1;
    arrangeVertically: boolean;
    points?: string[];
};

function FeatureCard(props: propsType) {
    return (
        <Card variant="outlined" sx={cardStyle}>
            {props.arrangeVertically ? (
                <Grid container rowSpacing={{ xs: 3, sm: 4 }}>
                    <Grid item xs={12} sx={gridItemStyle}>
                        <CardContent
                            sx={cardContentStyle}
                            style={{ padding: 0 }}
                        >
                            <Typography
                                sx={cardContentHeaderStyle}
                                align="center"
                            >
                                {props.header}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            ...gridItemStyle,
                            order: { xs: undefined, md: props.imagePosition },
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={cardMediaStyle}
                            image={props.image}
                            alt="Live from space album cover"
                        />
                    </Grid>
                    <Grid item xs={12} sx={gridItemStyle}>
                        <CardContent
                            sx={cardContentStyle}
                            style={{ padding: 0 }}
                        >
                            <Typography
                                sx={cardContentBodyStyle}
                                align={"justify"}
                            >
                                {props.content}
                            </Typography>
                            {props.points &&
                                props.points.map((point, index) => (
                                    <Typography
                                        key={index}
                                        sx={cardContentBodyStyle}
                                        align={"justify"}
                                    >
                                        {point}
                                    </Typography>
                                ))}
                        </CardContent>
                    </Grid>
                </Grid>
            ) : (
                <Grid container columnSpacing={5}>
                    <Grid
                        item
                        xs={5}
                        sx={{ ...gridItemStyle, order: props.imagePosition }}
                    >
                        <CardMedia
                            component="img"
                            sx={cardMediaStyle}
                            image={props.image}
                            alt="Live from space album cover"
                        />
                    </Grid>
                    <Grid item xs={7} sx={gridItemStyle}>
                        <CardContent
                            sx={cardContentStyle}
                            style={{ padding: 0 }}
                        >
                            <Typography
                                sx={cardContentHeaderStyle}
                                align={"left"}
                            >
                                {props.header}
                            </Typography>
                            <Typography
                                sx={cardContentBodyStyle}
                                align={"justify"}
                            >
                                {props.content}
                            </Typography>
                            {props.points &&
                                props.points.map((point, index) => (
                                    <Typography
                                        key={index}
                                        sx={{
                                            ...cardContentBodyStyle,
                                            marginTop: "20px",
                                        }}
                                        align={"justify"}
                                    >
                                        {point}
                                    </Typography>
                                ))}
                        </CardContent>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default FeatureCard;
