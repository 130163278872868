import { Button } from "@mui/material";
import React from "react";
import { buttonStyles } from "./navigationButton.style";

const ActionButton = ({
    label,
    triggerFunction,
    icon,
    iconPosition = "start",
    style,
}: {
    label: string;
    triggerFunction?: () => void;
    icon?: React.ReactNode;
    iconPosition?: "start" | "end";
    style?: any;
}) => {
    return (
        <>
            {iconPosition === "start" ? (
                <Button
                    onClick={triggerFunction}
                    variant="outlined"
                    startIcon={icon}
                    sx={{
                        ...buttonStyles,
                        width: style?.width ? style.width : buttonStyles.width,
                        height: style?.height ? style.height : undefined,
                    }}
                >
                    {label}
                </Button>
            ) : (
                <Button
                    onClick={triggerFunction}
                    variant="outlined"
                    endIcon={icon}
                    sx={{
                        ...buttonStyles,
                        width: style?.width ? style.width : buttonStyles.width,
                        height: style?.height ? style.height : undefined,
                    }}
                >
                    {label}
                </Button>
            )}
        </>
    );
};

export default ActionButton;
