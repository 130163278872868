import { Box, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import MessageCard from "./MessageCard";
import useData from "src/hooks/useApi";
import { payloadType } from "src/types/dataFetchingTypes";
import { OrderTypes } from "src/enums/sortingsEnum";
import { MessageDataType, MessagePopupType } from "src/types/messagesPageTypes";
import { getMessagesPostReq } from "src/data/test/messagePopupTest";
import { getRemainingTime, upperFirstLetter } from "src/utils/helperFunctions";
import useRouter from "src/hooks/useRouter";
import { Link } from "react-router-dom";
import EmptyMessages from "./EmptyMessages";
import LoadingIcon from "../common/LoadingIcon";

const MessagesPopup = ({
    open,
    setOpen,
    unreadList,
    setUnreadList,
}: MessagePopupType) => {
    const test = false;
    const { postData } = useData();
    const { currentRoute } = useRouter();
    const handleClose = () => setOpen(false);
    const [notifications, setNotifications] = useState<MessageDataType[]>([]);
    const [count, setCount] = useState<number>(0);
    const [allMsgAnimation, setAllMsgAnimation] = useState<boolean>(false);
    const [focusedMessage, setFocusedMessage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    function handleNotificationCount(id: number) {
        const unread = unreadList;
        if (unread.includes(id)) {
            setUnreadList(unread.filter((no) => !(no === id)));
        }
    }

    async function viewMessagesApi(ids: number[]) {
        try {
            const result: any = await postData(
                "/messages/updateMessageViewedAtTime",
                {
                    ids,
                },
            );
            return {
                success: result.success,
                status: result.status,
                data: result.data,
            };
        } catch (error: any) {
            console.log(`Message${ids.length > 1 ? "s" : ""} read failed`);
            return {
                success: false,
                message: error.response.data.message,
                status: error.response.status,
            };
        }
    }

    async function markAllAsRead() {
        const readSet = new Set<number>();
        const newList = notifications.map((message: MessageDataType) => {
            if (!message.isRead) {
                readSet.add(message.id);
                return {
                    ...message,
                    isRead: true,
                };
            } else return message;
        });
        setUnreadList([]);
        if (readSet.size > 0) {
            const response = await viewMessagesApi(Array.from(readSet));
            if (response && response.success) {
                setNotifications(newList);
            }
        }
    }

    async function clearAll() {
        const readSet = new Set<number>();
        notifications.forEach((message: MessageDataType) => {
            if (!message.isRead) {
                readSet.add(message.id);
                return {
                    ...message,
                    isRead: true,
                };
            } else return message;
        });
        setUnreadList([]);
        if (readSet.size > 0) {
            await viewMessagesApi(Array.from(readSet));
        }
        setAllMsgAnimation(true);
        setTimeout(() => {
            setNotifications([]);
            setAllMsgAnimation(false);
        }, count * 100);
    }

    async function messageClose(id: number) {
        const response = await viewMessagesApi([id]);
        if (response && response?.success) {
            setNotifications((messages) =>
                messages.filter((message) => {
                    if (!(message.id === id)) return message;
                }),
            );
        }
        handleNotificationCount(id);
    }

    async function markAsRead(id: number) {
        const response = await viewMessagesApi([id]);
        if (response && response?.success) {
            setNotifications((messages) =>
                messages.map((message) => {
                    if (message.id === id)
                        return {
                            ...message,
                            isRead: true,
                        };
                    else return message;
                }),
            );
        }
        handleNotificationCount(id);
    }

    async function getData() {
        setLoading(true);
        const payload: payloadType = {
            page: 1,
            size: 500,
            filterAnd: [
                {
                    key: "viewedAt",
                    operation: "IS NULL",
                },
            ],
            sort: {
                key: "sentAt",
                order: OrderTypes.DESC.toUpperCase(),
            },
        };
        try {
            // @ts-expect-error null response in already handled
            const response: MessagesResponseType = test
                ? await getMessagesPostReq()
                : await postData("/messages/getUserMessages", payload);
            if (response && response.data) {
                const unreadList: number[] = [];
                const messages: any = response?.data.map((msg: any) => {
                    unreadList.push(msg.id);
                    return {
                        id: msg.id,
                        level: msg.level,
                        message: msg.message,
                        isRead: false,
                        timeout: getRemainingTime(msg.sentAt),
                    };
                });
                setNotifications(messages);
                setUnreadList(unreadList);
            }
        } catch (error) {
            setNotifications([]);
            // showError("Messages fetching error");
        }
        setLoading(false);
    }
    useEffect(() => {
        getData();
    }, [currentRoute]);

    useEffect(() => {
        setCount(notifications.length);
    }, [notifications]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={messagePopupStyles.backdrop}
        >
            <Box sx={messagePopupStyles.container}>
                {/* Header links */}
                <Stack direction="row" sx={messagePopupStyles.header}>
                    <Typography
                        sx={{
                            ...messagePopupStyles.buttonLinks,
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#3A3541CC",
                            pb: "2px",
                        }}
                    >
                        {unreadList.length} Pending Notifications
                    </Typography>
                    <Typography
                        sx={{
                            ...messagePopupStyles.buttonLinks,
                            color: "#FF4D4F",
                        }}
                        onClick={() => clearAll()}
                    >
                        Clear All
                    </Typography>
                    <Typography
                        sx={{
                            ...messagePopupStyles.buttonLinks,
                            color: "#21B2E7",
                        }}
                        onClick={() => markAllAsRead()}
                    >
                        Mark all as Read
                    </Typography>
                    <Link
                        to="/messages"
                        style={{ textDecoration: "none" }}
                        onClick={handleClose}
                    >
                        <Stack direction="row">
                            <Typography
                                sx={{
                                    ...messagePopupStyles.buttonLinks,
                                    color: "#3A3541CC  !important",
                                    fontWeight: 500,
                                }}
                            >
                                View All
                            </Typography>
                            <BsArrowRightShort
                                style={{ color: "#3A3541CC !important" }}
                            />
                        </Stack>
                    </Link>
                </Stack>
                <Box sx={messagePopupStyles.contentContainer}>
                    {loading ? (
                        <Box
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            height="100%"
                        >
                            <LoadingIcon />
                        </Box>
                    ) : (notifications && notifications.length) > 0 ? (
                        notifications.map((message, index) => (
                            <MessageCard
                                key={message.id}
                                type={message.level}
                                id={message.id}
                                time={message.timeout}
                                markAsRead={markAsRead}
                                messageClose={messageClose}
                                isRead={message.isRead}
                                idx={index}
                                allMsgAnimation={allMsgAnimation}
                                setFocusedMessage={setFocusedMessage}
                                focusedMessage={focusedMessage}
                            >
                                {upperFirstLetter(message.message)}
                            </MessageCard>
                        ))
                    ) : (
                        <EmptyMessages>No unread messages</EmptyMessages>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

const messagePopupStyles = {
    backdrop: {
        "& .MuiBackdrop-root": {
            opacity: "0 !important",
        },
    },
    container: {
        position: "absolute",
        top: "30px",
        right: { sm: "90px", xs: 0 },
        // transform: "translate(-50%, -50%)",
        width: { sm: 400, xs: "90%" },
        height: 450,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        borderRadius: 1,
        boxShadow: 2,
        p: 3,
        border: 0,
        outline: "none",
    },
    buttonLinks: {
        whiteSpace: "nowrap",
        fontSize: "11px",
        cursor: "pointer",
        fontWeight: 500,
    },
    header: {
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
    },
    contentContainer: {
        my: 1,
        py: 1,
        px: 1,
        height: 390,
        bgcolor: "#FAFAFA",
        overflowY: "scroll",
        overflowX: "hidden",
        transition: "all 0.5s",
    },
};

export default MessagesPopup;
