import React from "react";

import autoBackorderLogo from "../../assets/images/headerVerticalLogo.webp";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
    dividerStyle,
    footerContainerStyle,
    linkStyle,
    clickableText,
    footerSpacing,
} from "./Footer.style";
import useMessage from "src/hooks/useMessage";
import { useNavigate } from "react-router-dom";

function Footer() {
    const { showSuccess } = useMessage();
    const navigate = useNavigate();

    const phoneNumber = "+1 646 8139726";
    const email = "info@autobackorder.com";

    const handlePhoneClick = async () => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(phoneNumber);
                showSuccess("Phone number copied to clipboard!");
            }
        } catch (error) {
            console.error("Failed to copy phone number:", error);
        }
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };
    return (
        <Grid container sx={footerContainerStyle}>
            <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <img
                    src={autoBackorderLogo}
                    alt="Logo"
                    loading="lazy"
                    width={"15%"}
                />
                <Divider orientation="horizontal" flexItem sx={dividerStyle} />
            </Grid>
            <Grid
                item
                container
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                sx={{
                    gap: { xs: 2, md: 10 },
                }}
            >
                <Grid item>
                    <Typography
                        onClick={() => navigate("/about-us")}
                        sx={linkStyle}
                        color={"inherit"}
                        style={{ textDecoration: "none" }}
                    >
                        {"About us"}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        onClick={() => navigate("/how-it-works")}
                        sx={linkStyle}
                        color={"inherit"}
                        style={{ textDecoration: "none" }}
                    >
                        {"How it Works"}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        onClick={() => navigate("/tos")}
                        sx={linkStyle}
                        color={"inherit"}
                        style={{ textDecoration: "none" }}
                    >
                        {"Terms of Service"}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        onClick={() => navigate("/privacy")}
                        sx={linkStyle}
                        color={"inherit"}
                        style={{ textDecoration: "none" }}
                    >
                        {"Privacy"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={clickableText}
                    onClick={() => handlePhoneClick()}
                >
                    {phoneNumber}
                </Typography>
            </Grid>
            <Grid item xs={12} paddingBottom={footerSpacing}>
                <Typography
                    sx={clickableText}
                    onClick={() => handleEmailClick()}
                >
                    {email}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Footer;
