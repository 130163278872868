import { Stack, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupButton from "../common/PopupButton";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import useData from "src/hooks/useApi";
import useMessage from "src/hooks/useMessage";
import {
    getMinimumBid,
    onlyNumbersWithDot,
    onlyNumericalKeyPress,
} from "../../utils/helperFunctions";
import { AuctionTableData } from "../../types/postAuctionTypes";
import Big from "big.js";
import { AddBidErrorsEnum } from "src/enums/auctionPageEnum";
// import { calculateBidValue } from "src/utils/helperFunctions";

const stepCount = Number(process.env.REACT_APP_AUCTION_INCREMENT);

const UpdateModel = ({
    open,
    setOpen,
    getData,
    selectedRow,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    getData: (isReset?: boolean) => void;
    selectedRow: AuctionTableData | undefined;
}) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const [loading, setLoading] = useState<boolean>();
    const [updateError, setUpdateError] = useState<{
        hasError: boolean;
        error: string;
        isTouched: boolean;
    }>({
        hasError: false,
        error: "",
        isTouched: false,
    });

    const [amount, setAmount] = useState<string>("");
    const [maxBid, setMaxBid] = useState<number>();

    const handleClose = () => {
        setOpen(false);
        setUpdateError({
            error: "Required Field",
            hasError: false,
            isTouched: false,
        });
        setAmount("");
    };

    function isBidValid(): boolean {
        if (amount === "") {
            setUpdateError((updateError) => ({
                ...updateError,
                hasError: true,
                error: "Required Field",
            }));
            return false;
        }

        if (!selectedRow) {
            setUpdateError((updateError) => ({
                ...updateError,
                hasError: true,
                error: "No Row Selected",
            }));
            return false;
        }

        if (!maxBid) {
            setUpdateError((updateError) => ({
                ...updateError,
                hasError: true,
                error: "Max Bid Not Available",
            }));
            return false;
        }

        if (Number(amount) < maxBid) {
            setUpdateError((updateError) => ({
                ...updateError,
                hasError: true,
                error: `${AddBidErrorsEnum.LOW_BID} of ${maxBid}`,
            }));
            return false;
        }

        setUpdateError((updateError) => ({
            ...updateError,
            hasError: false,
            error: "",
        }));
        return true;
    }

    async function handleUpdate() {
        setLoading(true);
        try {
            if (isBidValid()) {
                if (selectedRow) {
                    // TODO : The post request for update
                    const response: any = await postData("/bid/updatePostBid", {
                        domainName: selectedRow.domainName,
                        price: new Big(amount).toFixed(2),
                    });
                    if (response.success) {
                        showSuccess(
                            `Bid for ${selectedRow.domainName} domain successfully updated`,
                        );
                        getData();
                    } else {
                        showError(
                            `Bid update for ${selectedRow.domainName} failed `,
                        );
                    }
                    setOpen(false);
                    setAmount("");
                } else {
                    throw new Error("update error");
                }
            } else {
                setUpdateError((updateError) => ({
                    ...updateError,
                    isTouched: true,
                }));
            }
        } catch (error: any) {
            setOpen(false);
            if (
                error?.response?.data?.message === AddBidErrorsEnum.LOW_BALANCE
            ) {
                showError(AddBidErrorsEnum.LOW_BALANCE);
            } else {
                showError("Bid update failed");
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!selectedRow) {
            setOpen(false);
        } else {
            const tempMaxBid = getMinimumBid(
                Number(selectedRow.currentPrice),
                Number(selectedRow.amount),
                stepCount,
            );
            if (tempMaxBid) {
                setAmount(tempMaxBid.toFixed(2));
                setMaxBid(Number(tempMaxBid.toFixed(2)));
            }
        }
    }, [selectedRow, open]);

    return (
        <PopupModelWrapper
            handleClose={handleClose}
            open={open}
            title="Update Bid"
        >
            <Stack sx={{ mx: 2.5, my: 1 }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "17px",
                        color: "#3A3541CC",
                        pt: 1,
                    }}
                >
                    Confirm your Bid for{" "}
                    {selectedRow?.domainName ? selectedRow.domainName : ""}{" "}
                    Domain
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#3A354180",
                        pb: 1,
                    }}
                >
                    This action cannot be undone
                </Typography>
                <Stack direction="row" alignItems="center" mt={1}>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "#3A354180",
                            mr: 2,
                        }}
                    >
                        Amount
                    </Typography>

                    <TextField
                        // id="city"
                        name="amount"
                        type="number"
                        onKeyDownCapture={onlyNumericalKeyPress}
                        onChange={(e) => {
                            const value = onlyNumbersWithDot(e.target.value);
                            setAmount(value);
                        }}
                        value={amount}
                        sx={{
                            "& .MuiInputBase-formControl": {
                                borderRadius: 2,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3A354180",
                            },
                        }}
                        inputProps={{
                            style: {
                                padding: "4px 8px",
                                width: "100px",
                                textAlign: "right",
                                fontSize: "14px",
                            },
                        }}
                        error={updateError.isTouched && updateError.hasError}
                    />
                </Stack>
                {updateError.isTouched && updateError.hasError && (
                    <Typography
                        sx={{
                            fontSize: "12px",
                            color: "#FF4D4F",
                            mt: "5px",
                        }}
                    >
                        {updateError.error}
                    </Typography>
                )}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
                <PopupButton triggerFunction={handleClose} type="cancel">
                    Cancel
                </PopupButton>
                <PopupButton
                    triggerFunction={handleUpdate}
                    type="proceed"
                    disabled={loading}
                >
                    Proceed
                </PopupButton>
            </Stack>
        </PopupModelWrapper>
    );
};

export default UpdateModel;
