import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import { Stack, Typography } from "@mui/material";
import TopUpChipButton from "./TopUpChipButton";
import PopupButton from "../common/PopupButton";
import useAuth from "../../hooks/useAuth";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import {
    dataErrorCallback,
    dataPopupClosed,
    dataPopupWebhookReceived,
    setupFastspring,
} from "../../utils/fastspringUtils";

const TopUpPopUp = ({
    open,
    setOpen,
    message,
}: {
    open: boolean;
    message: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const [activeChipButton, setActiveChipButton] = useState<number>(50);

    const setAlertFunc = () => {
        handleClose();
        buyProduct();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const auth = useAuth();
    const api = useData();
    const { showSuccess } = useMessage();
    // @ts-expect-error expecting an error
    const fastspring = window.fastspring;

    useEffect(() => {
        // @ts-expect-error not an error
        window.dataErrorCallback = (code: number, message: string) =>
            dataErrorCallback(code, message, auth);
        // @ts-expect-error not an error
        window.dataPopupWebhookReceived = (details: any) =>
            dataPopupWebhookReceived(details, activeChipButton, auth, api);
        // @ts-expect-error not an error
        window.dataPopupClosed = (details: any) =>
            dataPopupClosed(details, showSuccess);
    }, [fastspring, activeChipButton]);

    const buyProduct = () => {
        if (fastspring?.builder) {
            setupFastspring(
                fastspring,
                activeChipButton,
                auth.user?.email || "",
                auth.user?.username || "",
            );
        }
    };

    return (
        <>
            <PopupModelWrapper
                open={open}
                handleClose={handleClose}
                title="Top-up Confirmation"
                width={{ md: "600px", sm: "300px", xs: "300px" }}
            >
                <Stack>
                    <Typography
                        sx={{
                            fontSize: { xs: 12, sm: 14 },
                            fontWeight: 500,
                            color: "#3A3541CC",
                            padding: 2,
                        }}
                    >
                        {message}
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        sx={{ mb: 1, mx: 1 }}
                    >
                        <TopUpChipButton
                            activeValue={activeChipButton}
                            setActiveChipButton={setActiveChipButton}
                        >
                            {20}
                        </TopUpChipButton>
                        <TopUpChipButton
                            activeValue={activeChipButton}
                            setActiveChipButton={setActiveChipButton}
                        >
                            {50}
                        </TopUpChipButton>
                        <TopUpChipButton
                            activeValue={activeChipButton}
                            setActiveChipButton={setActiveChipButton}
                        >
                            {100}
                        </TopUpChipButton>
                        <TopUpChipButton
                            activeValue={activeChipButton}
                            setActiveChipButton={setActiveChipButton}
                        >
                            {200}
                        </TopUpChipButton>
                        <TopUpChipButton
                            activeValue={activeChipButton}
                            setActiveChipButton={setActiveChipButton}
                        >
                            {500}
                        </TopUpChipButton>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ mb: 1, mx: 1 }}
                    >
                        <PopupButton
                            triggerFunction={handleClose}
                            height="40px"
                            type="cancel"
                            id="domains-input-popup-cancel"
                        >
                            Cancel
                        </PopupButton>
                        <PopupButton
                            triggerFunction={setAlertFunc}
                            type="proceed"
                            height="40px"
                            id="domains-input-popup-add"
                        >
                            Top-up
                        </PopupButton>
                    </Stack>
                </Stack>
            </PopupModelWrapper>
        </>
    );
};

export default TopUpPopUp;
