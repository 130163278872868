export const cardStyle = {
    width: { xs: "320px", sm: "450px" },
    backgroundColor: "transparent",
};

export const footerTextStyle = {
    textAlign: "center",
    fontSize: "15px",
    color: "rgba(241, 214, 49, 1)",
    mt: "10px",
};

export const inputStyle = {
    " & .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "15px",
        backgroundColor: "white",
    },
    " & .MuiFormHelperText-root": {
        marginX: 0,
        marginTop: "5px",
    },
    " & .MuiOutlinedInput-input:-webkit-autofill": {
        height: "10px",
    },
};

export const signUpButtonStyle = {
    backgroundColor: "#EFD016",
    borderRadius: "12px",
    height: { xs: "40px", sm: "45px" },
    width: "100%",
    color: "rgba(58, 53, 65, 0.8)",
    fontWeight: 500,
    textTransform: "none",
    fontSize: { xs: "15px", sm: "20px" },
    "&:hover": {
        backgroundColor: "#EFD016",
        boxShadow: "0 0 0 4px #EFD016",
    },
    "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "#EFD016",
    },
    mt: { xs: "25px", sm: "30px" },
};

export const linkStyle = {
    fontWeight: 500,
    color: "white",
    pl: "5px",
};

export const linkTextStyle = {
    fontSize: "15px",
    color: "rgba(241, 214, 49, 1)",
    mt: "20px",
};

// Revamp Styles

export const signInFormContainer = {
    paddingLeft: { xs: "40px", sm: "60px" },
    paddingRight: { xs: "40px", sm: "60px", md: "0px" },
};

export const headerStyle = {
    textAlign: "left",
    fontSize: { xs: "35px", lg: "40px" },
    fontWeight: 700,
    color: "white",
    marginBottom: { xs: "20px", lg: "25px" },
};

export const formBorderRadius = "20px";
export const formItemHeight = { xs: "40px", md: "42px" };
export const formItemFontSize = { xs: "14px", md: "16px" };

export const formLabelStyle = {
    color: "white",
    fontSize: formItemFontSize,
    marginBottom: { xs: "7px", md: "10px" },
};

export const formTextInputStyle = {
    " & .MuiOutlinedInput-root": {
        height: formItemHeight,
        borderRadius: formBorderRadius,
        backgroundColor: "white",
        marginBottom: { xs: "21px", md: "30px" },
    },
    " & .MuiFormHelperText-root": {
        marginX: 0,
        marginTop: { xs: "-16px", md: "-25px" },
        marginBottom: "20px",
    },
    " & .MuiOutlinedInput-input:-webkit-autofill": {
        height: "0px",
        marginX: "7px",
    },
};

export const registerTextInputStyle = {
    " & .MuiOutlinedInput-root": {
        height: formItemHeight,
        borderRadius: formBorderRadius,
        backgroundColor: "white",
        marginBottom: { xs: "20px", md: "22px" },
    },
    " & .MuiFormHelperText-root": {
        marginX: 0,
        marginTop: { xs: "-15px", md: "-17px" },
        marginBottom: "5px",
    },
    " & .MuiOutlinedInput-input:-webkit-autofill": {
        height: "0px",
        marginX: "7px",
    },
};

export const formSubmitButtonStyle = {
    backgroundColor: "#EFD016",
    borderRadius: formBorderRadius,
    height: formItemHeight,
    width: { xs: "50%", md: "40%", lg: "30%" },
    color: "black",
    textTransform: "none",
    fontSize: formItemFontSize,
    "&:hover": {
        backgroundColor: "#EFD016",
    },
    "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
    },
};
