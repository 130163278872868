export const filterStyles = {
    container: {
        width: "100%",
        mb: 3,
        display: "flex",
        flexDirection: { md: "row", xs: "column-reverse" },
        justifyContent: "flex-start",
        // alignItems: "center",
    },
    datePickerStyle: {
        "& .MuiOutlinedInput-input": {
            py: "12px",
            fontSize: "14px",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 20,
        },
        "& .MuiIconButton-edgeEnd > svg": {
            fontSize: 18,
        },
        "& .MuiIconButton-edgeEnd": {
            mr: 0,
        },
    },
};

export const containerStyle = {
    paddingTop: { xs: 2, sm: 3 },
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    borderRadius: 1,
    bgcolor: "#FFFFFF",
};
