import { Checkbox, Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

const CheckBoxWithLabel = ({
    label,
    isChecked,
    setIsChecked,
    disabled = false,
    sx,
}: {
    label: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    disabled?: boolean;
    sx?: any;
}) => {
    return (
        <Grid
            container
            component={"span"}
            sx={sx}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Grid item xs={10}>
                <Typography color={"#3A3541CC"}>{label}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Checkbox
                    disabled={disabled}
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            margin: "0 !important",
                            padding: 0,
                        },
                        margin: 0,
                        padding: 0,
                    }}
                    checked={isChecked}
                    onChange={() => {
                        setIsChecked((prevState) => !prevState);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default CheckBoxWithLabel;
