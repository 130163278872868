import {
    PostAuctionEnum,
    PreAuctionEnum,
    UserStatusEnum,
} from "../enums/allBidsPageEnums";

export const statusSelections = [
    {
        label: "In Progress",
        value: PreAuctionEnum.INPROGRESS,
    },
    {
        label: "Success",
        value: PreAuctionEnum.SUCCESS,
    },
    {
        label: "Failure",
        value: PreAuctionEnum.FAILURE,
    },
    {
        label: "Archived",
        value: PreAuctionEnum.ARCHIVED,
    },
    {
        label: "Payment Pending",
        value: PreAuctionEnum.PAYMENT,
    },
];

export const backorderStatusSelections = [
    {
        label: "In Progress",
        value: PreAuctionEnum.INPROGRESS,
    },
    {
        label: "Payment Pending",
        value: PreAuctionEnum.PAYMENT,
    },
    {
        label: "Submission Pending",
        value: UserStatusEnum.SUBMISSIONPEND,
    },
    {
        label: "Result Pending",
        value: UserStatusEnum.RESULT_PENDING,
    },
];

export const myAuctionsStatusSelections = [
    {
        label: "In Progress",
        value: PostAuctionEnum.INPROGRESS,
    },
    {
        label: "Payment Pending",
        value: PostAuctionEnum.PAYMENT,
    },
];

export const resultsStatusSelections = [
    {
        label: "Success",
        value: PreAuctionEnum.SUCCESS,
    },
    {
        label: "Failure",
        value: PreAuctionEnum.FAILURE,
    },
    {
        label: "Archived",
        value: UserStatusEnum.ARCHIVED,
    },
    {
        label: "Auction Lost",
        value: UserStatusEnum.AUCTION_LOST,
    },
];
