import { Box, Typography } from "@mui/material";
import React from "react";
import { regProfileStyles } from "./registrantProfileStyles";

const ProfileTextInputWrapper = ({
    children,
    warning,
    hasWarning,
    label,
}: {
    children: React.ReactNode;
    warning: string | undefined;
    hasWarning: boolean | undefined;
    label: string;
}) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={regProfileStyles.inputWrapper.label}>
                {label}
            </Typography>
            {children}
            {hasWarning && (
                <Typography sx={regProfileStyles.inputWrapper.errorMsg}>
                    {warning}
                </Typography>
            )}
        </Box>
    );
};

export default ProfileTextInputWrapper;
