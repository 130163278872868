import { Fragment, useMemo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useMessage from "src/hooks/useMessage";
import {
    formLabelStyle,
    formSubmitButtonStyle,
    formTextInputStyle,
    headerStyle,
    signInFormContainer,
} from "./Form.styles";
import SpinnerLoader from "src/components/loader/SpinnerLoader";
import { emailRegex } from "src/utils/registrantProfileData";
import { getAuthPageContainerStyle } from "src/utils/helperFunctions";

function ForgotPasswordForm() {
    const navigate = useNavigate();
    const { showError } = useMessage();

    // Forgot Password General State

    const [loading, setLoading] = useState(false);
    const containerStyle = useMemo(() => getAuthPageContainerStyle(window), []);

    const validationSchema = yup.object({
        email: yup
            .string()
            .matches(emailRegex, "Invalid email format")
            .required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
        async onSubmit(values) {
            setLoading(true);
            try {
                const url =
                    process.env.REACT_APP_BACKEND_URL + "/getTempPasswd";
                await axios.post(
                    url,
                    JSON.stringify({
                        email: values.email,
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    },
                );
                navigate("/changePassword", {
                    state: {
                        message: "We sent you the temporary password via email",
                        email: formik.values.email,
                    },
                });
            } catch (error: any) {
                if (error.response?.status === 404) {
                    showError("Invalid email");
                } else if (
                    error.response?.status === 500 ||
                    error.response?.status === 503
                ) {
                    showError("Internal server error");
                } else if (error.response?.status === 401) {
                    showError("User not recognized");
                } else {
                    showError("Password change failed");
                }
            }
            setLoading(false);
        },
    });

    return (
        <Fragment>
            <Grid
                zIndex={0}
                container
                sx={containerStyle}
                alignContent={"left"}
            >
                <Grid item xs={12} md={6} lg={4.5} sx={signInFormContainer}>
                    <Typography sx={headerStyle}>ACCOUNT RECOVERY</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <InputLabel htmlFor="email" sx={formLabelStyle}>
                            Email
                        </InputLabel>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="off"
                            sx={formTextInputStyle}
                            value={formik.values.email}
                            onChange={(event) => {
                                formik.setFieldValue(
                                    "email",
                                    event.target.value.trim(),
                                );
                            }}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                        <Button
                            type="submit"
                            sx={formSubmitButtonStyle}
                            disabled={loading}
                            endIcon={
                                <SpinnerLoader
                                    loading={loading}
                                    size={20}
                                    sx={{ marginLeft: "10px" }}
                                />
                            }
                        >
                            Proceed
                        </Button>
                    </form>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"20px"}
                    >
                        <Typography
                            sx={{ ...formLabelStyle, marginRight: "10px" }}
                        >
                            Back to
                        </Typography>
                        <Typography
                            sx={{
                                ...formLabelStyle,
                                cursor: "pointer",
                                color: "#EFD016",
                            }}
                            onClick={() => navigate("/login")}
                        >
                            Sign In
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ForgotPasswordForm;
