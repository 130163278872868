import { Dispatch, SetStateAction } from "react";
import { OrderTypes } from "src/enums/sortingsEnum";
import {
    AuctionTypeEnum,
    DomainStatusEnum,
    UserStatusEnum,
} from "../enums/allBidsPageEnums";

export enum AuctionStatusEnum {
    ONGOING = "Ongoing",
    CLOSED = "Closed",
    LOCKED = "Locked",
    CANCELLED = "Cancelled",
    PAYMENT_PENDING = "Pending",
}

export type dataType =
    | "id"
    | "domainName"
    | "amount"
    | "status"
    | "progressStatus"
    | "auctionEnd"
    | "auctionStatus"
    | "bidders"
    | "auctionType"
    | "currentPrice";

export type data = {
    id: number;
    domainName: string;
    amount: Big.Big;
    status: UserStatusEnum;
    auctionEnd: number;
    bidders: number;
    auctionStatus: AuctionStatusEnum;
    winningPrice: number;
    domainStatus: DomainStatusEnum;
    isArchived: boolean;
    auctionType: AuctionTypeEnum;
    currentPrice: number;
    auctionId: number;
};

export interface HeadingType {
    id: dataType;
    label: string;
    sorting?: boolean;
    align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}

export interface PostAuctionHeadRowType {
    order: OrderTypes;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    orderBy: dataType;
    headRow: HeadingType[];
}

export interface OrderPropsType {
    order: OrderTypes;
    orderBy: dataType;
}

interface BaseTableData {
    id: number;
    domainName: string;
    amount: Big.Big;
    status: UserStatusEnum;
    auctionEnd: number | string;
    bidders: number;
    auctionStatus: AuctionStatusEnum;
    domainStatus: DomainStatusEnum;
    isArchived: boolean;
    auctionType: AuctionTypeEnum;
    currentPrice: number;
    auctionId: number;
}

export interface ResultTableData extends BaseTableData {
    winningPrice: Big.Big;
}

export interface AuctionTableData extends BaseTableData {}

export interface ResponseDataType {
    data: data[];
    count: number | null;
}

export const postAuctionHeading: HeadingType[] = [
    {
        id: "domainName",
        label: "Domain",
        align: "left",
        sorting: true,
    },
    {
        id: "currentPrice",
        label: "Current Price ($)",
        align: "center",
        sorting: true,
    },
    {
        id: "amount",
        label: "My Bid ($)",
        align: "center",
        sorting: true,
    },
    {
        id: "status",
        label: "Status",
        align: "center",
    },
    {
        id: "progressStatus",
        label: "Progress Status",
        align: "center",
    },
    {
        id: "auctionEnd",
        label: "Time Left",
        align: "center",
        sorting: true,
    },
    {
        id: "bidders",
        label: "Bidders",
        align: "center",
        sorting: true,
    },
    {
        id: "id",
        label: "Actions",
        align: "center",
    },
];

export const resultsHeading: HeadingType[] = [
    {
        id: "domainName",
        label: "Domain",
        align: "left",
        sorting: true,
    },
    {
        id: "auctionType",
        label: "Auction Type",
        align: "center",
    },
    {
        id: "amount",
        label: "My Bid ($)",
        align: "center",
        sorting: true,
    },
    {
        id: "status",
        label: "Progress Status",
        align: "center",
    },
    {
        id: "auctionEnd",
        label: "Auction End",
        align: "center",
        sorting: true,
    },
    {
        id: "bidders",
        label: "Bidders",
        align: "center",
        sorting: true,
    },
];
