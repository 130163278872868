export const tableHead = {
    headStyle: { color: "#3A3541CC", fontWeight: 500, fontSize: "14px" },
    divider: {
        height: "30px",
        bgcolor: "#D9D9D9",
        width: "2px",
        borderRadius: "15px",
        position: "absolute",
        right: 0,
        top: 18,
    },
    sortIcon: {
        width: "7px",
        padding: 0,
        margin: 0,
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
    },
};
