import { contentFontSize, headerFontSize } from "../SharedStyles";

export const cardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
    paddingX: { xs: "20px", sm: "30px", md: "50px", lg: "150px" },
    boxShadow: 0,
};

export const gridContainerStyle = {
    maxWidth: "1100px",
};

export const gridItemStyle = {
    marginBottom: "30px",
};

export const boxStyle = {
    display: "flex",
    flexDirection: "column",
};

export const cardHeaderStyle = {
    fontSize: headerFontSize,
    fontWeight: 700,
    marginY: "15px",
    width: "60%",
    maxWidth: "1100px",
    color: "#ffb700",
};

export const cardContentTextStyle = {
    fontSize: contentFontSize,
    maxWidth: "1100px",
    marginBottom: "20px",
};

export const cardContentStyle = {
    flex: "1 0 auto",
    paddingX: "10px",
};

export const faqRowSpacing = 0.5;
