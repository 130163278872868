import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import TabSet from "../../components/common/TabSet";
import MyAuctionsPage from "../postBids/MyAuctionsPage";
import { AllBidsProvider } from "../../context/AllBidsContext";
import PreBidsPage from "../preBids/PreBidsPage";
import ResultsPage from "./ResultsPage";
import { mainContentContainer } from "src/components/SharedStyles";
import { useNavigate, useParams } from "react-router-dom";

export const tabTypes = ["preBids", "postBids", "results"];

const tabData = [
    {
        label: "My Backorders",
        value: tabTypes[0],
    },
    {
        label: "My Auctions",
        value: tabTypes[1],
    },
    {
        label: "My Results",
        value: tabTypes[2],
    },
];

const Dashboard = () => {
    const { selectedTab } = useParams();
    let initTab;

    if (selectedTab && tabTypes.includes(selectedTab)) {
        initTab = selectedTab;
    } else {
        initTab = tabTypes[0];
    }

    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState<string>(initTab);

    useEffect(() => {
        navigate(`/dashboard/${currentTab}`);
    }, [currentTab]);

    return (
        <Box sx={mainContentContainer}>
            <TabSet
                currentTab={currentTab ?? tabTypes[0]}
                setCurrentTab={setCurrentTab}
                data={tabData}
            />
            {currentTab === "preBids" && (
                <AllBidsProvider>
                    <PreBidsPage currentTab={currentTab} />
                </AllBidsProvider>
            )}
            {currentTab === "postBids" && (
                <MyAuctionsPage currentTab={currentTab} />
            )}
            {currentTab === "results" && (
                <ResultsPage currentTab={currentTab} />
            )}
        </Box>
    );
};

export default Dashboard;
