import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const zipRegex = /^[A-Z0-9]+$/;
export const phoneRegex = /^\+?\d{1,3}-?\d{3}-?\d{3}-?\d{4}$/;
export const nameserverRegex =
    /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}$/;
// export const nameserverRegex =
//     /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}\.?$/;

export const domainsValidationSchema = yup.object().shape({
    nameserver1: yup
        .string()
        .required("Required Field")
        .matches(nameserverRegex, "Invalid nameserver"),
    nameserver2: yup
        .string()
        .required("Required Field")
        .matches(nameserverRegex, "Invalid nameserver"),
    // .test(
    //     "notEqual1",
    //     "Must be different from nameserver2",
    //     //@ts-expect-error error
    //     (value) => value !== this.parent.nameserver1,
    // ),
});

export const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    company: yup.string(),
    street: yup.string().required("Street is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    zip: yup
        .string()
        .required("Zip is required")
        .matches(zipRegex, "Invalid zip"),
    phone: yup
        .string()
        .required("Phone is required")
        .test("isValidPhoneNumber", "Invalid phone number", (value: string) =>
            isValidPhoneNumber(value),
        ),
    email: yup
        .string()
        .required("Email is required")
        .matches(emailRegex, "Invalid Email"),
});

export const inputs = {
    name: "",
    company: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    email: "",
};

export const inputErrors = {
    name: "Required Field",
    company: "",
    street: "Required Field",
    city: "Required Field",
    state: "Required Field",
    country: "Required Field",
    zip: "Required Field",
    phone: "Required Field",
    email: "Required Field",
};
