export enum LevelEnum {
    INFO = "info",
    DANGER = "danger",
    WARN = "warning",
}

export enum LevelTextColors {
    DANGER = "#FF5D5F",
    WARN = "#FBBD42",
    INFO = "#21B2E7",
}

export enum LevelBgColors {
    DANGER = "#FFF5F5",
    WARN = "#FFFAEF",
    INFO = "#E4F8FF",
}

export enum Constants {
    POPUP_MSG_LENGTH = 50,
}
