export const headerStyle = {
    position: "sticky",
    width: "100%",
    zIndex: 10,
    top: 0,
    left: 0,
    display: "flex",
};

export const headerBackgroundStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1,
    background: "black",
};

export const yellowSectionStyle = {
    height: "101%",
    backgroundColor: "#efd016",
    // _ _ : x y
    clipPath: "polygon(0 0, 40% 0, 37.5% 100%, 0 100%)",
};
export const blackLineStyle = {
    height: "100%",
    backgroundColor: "black",
    clipPath: "polygon(38% 0, 36% 0, 33.5% 100%, 35.5% 100%)",
};

export const navContentStyle = {
    outerBox: {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    logoHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    navLinkHolder: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-around",
        position: { xs: "fixed", md: "initial" },
        width: { xs: "100%", md: "auto" },
        bgcolor: { xs: "black", md: "transparent" },
        transition: "all 0.5s",
        zIndex: { xs: -2, md: 0 },
    },
};

export const navToggleIcon = {
    bar: {
        width: "20px",
        height: "3px",
        bgcolor: "#EFD016",
        borderRadius: 10,
        my: "2px",
        transition: "all 0.5s",
    },
};

export const avatarButton = {
    menuBox: {
        position: "absolute",
        width: "200px",
        bgcolor: "black",
        right: 5,
        top: "30px",
        borderRadius: 1,
        border: 1,
        borderColor: "white",
        px: 1,
    },
    menuItem: {
        fontSize: {
            xs: "12px",
            md: "14px",
            cursor: "pointer",
        },
        p: 1,
        "&:hover": {
            color: "#EFD016",
        },
    },
};
