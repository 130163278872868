export const datePickerStyle = {
    "& .MuiOutlinedInput-input": {
        py: "12px",
        fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: 20,
    },
    "& .MuiIconButton-edgeEnd > svg": {
        fontSize: 18,
    },
    "& .MuiIconButton-edgeEnd": {
        mr: 0,
    },
    "& .MuiInputLabel-root": {
        lineHeight: "13px",
        fontSize: "13px",
    },
    width: "100%",
};
