import { Chip, styled } from "@mui/material";
import {
    ProgressStatusBgColors,
    ProgressStatusEnum,
    ProgressStatusTextColors,
} from "src/enums/allBidsPageEnums";

const SuccessStatusChip = ({
    type,
    label,
}: {
    type: ProgressStatusEnum;
    label?: string;
}) => {
    const colors = {
        success: {
            text: ProgressStatusTextColors.SUCCESS,
            bg: ProgressStatusBgColors.SUCCESS,
        },
        failure: {
            text: ProgressStatusTextColors.FAILURE,
            bg: ProgressStatusBgColors.FAILURE,
        },
    };

    const StyledChip = styled(Chip)(() => ({
        fontSize: "12px",
        borderRadius: 5,
        height: "25px",
    }));

    switch (type) {
        case ProgressStatusEnum.SUCCESS:
            return (
                <StyledChip
                    label={label ?? "Success"}
                    sx={{
                        color: colors.success.text,
                        bgcolor: colors.success.bg,
                    }}
                />
            );
        case ProgressStatusEnum.FAILURE:
            return (
                <StyledChip
                    label={label ?? "Failed"}
                    sx={{
                        color: colors.failure.text,
                        bgcolor: colors.failure.bg,
                    }}
                />
            );
        default:
            return (
                <StyledChip
                    label={label ?? "Success"}
                    sx={{
                        color: colors.failure.text,
                        bgcolor: colors.failure.bg,
                    }}
                />
            );
    }
};

export default SuccessStatusChip;
