export enum SubmissionStatusEnum {
    PENDING = "Pending",
    SUCCESS = "Success",
    FAILURE = "Failure",
}

export enum SubmissionStatusTextColors {
    FAILURE = "#FF4D4F",
    PENDING = "#FAB529",
    SUCCESS = "#52C41A",
}

export enum SubmissionStatusBgColors {
    FAILURE = "#FFF1F0",
    PENDING = "#FEFFC5",
    SUCCESS = "#F6FFED",
}
