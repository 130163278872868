import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";

// Eager Page Loading

import LandingPage from "../pages/landingPage/LandingPage";
import SignUpForm from "../forms/SignUpForm";
import SignInForm from "../forms/SignInForm";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import ChangePasswordForm from "../forms/ChangePasswordForm";
import RegistrantProfilePage from "../pages/registrantProfilePage/RegistrantProfilePage";
import BalancePage from "../pages/balancePage/BalancePage";
import ProtectedRoute from "./ProtectedRoute";
import SubmissionsPage from "../pages/submissionsPage/SubmissionsPage";
import MessagesPage from "../pages/messagesPage/MessagesPage";
import Dashboard, { tabTypes } from "../pages/allBids/Dashboard";
import AuctionsPage from "../pages/auctionsPage/AuctionsPage";
import { Layout } from "src/components/layout/Layout";

// Lazy Page Loading

const AboutUsPage = lazy(() => import("../pages/infoPages/AboutUsPage"));
const HowItWorksPage = lazy(() => import("../pages/infoPages/HowItWorksPage"));
const PrivacyPage = lazy(() => import("../pages/infoPages/PrivacyPage"));
const ToSPage = lazy(() => import("../pages/infoPages/ToSPage"));

export default function AllRoutes() {
    return useRoutes([
        {
            path: "/",
            element: <Layout showFooter={true} />,
            children: [
                { path: "/", element: <LandingPage /> },
                { path: "/about-us", element: <AboutUsPage /> },
                { path: "/how-it-works", element: <HowItWorksPage /> },
                { path: "/privacy", element: <PrivacyPage /> },
                { path: "/tos", element: <ToSPage /> },
            ],
        },
        {
            path: "/",
            element: <Layout showFooter={false} />,
            children: [
                { path: "/register", element: <SignUpForm /> },
                { path: "/login", element: <SignInForm /> },
                { path: "/forgotPassword", element: <ForgotPasswordForm /> },
                { path: "/changePassword", element: <ChangePasswordForm /> },
            ],
        },
        {
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/balance",
                    element: <BalancePage />,
                },
                {
                    path: "/submissions",
                    element: <SubmissionsPage />,
                },
                {
                    path: "/messages",
                    element: <MessagesPage />,
                },
                {
                    path: "/dashboard/:selectedTab",
                    element: <Dashboard />,
                },
                {
                    path: "/registrantProfile",
                    element: <RegistrantProfilePage />,
                },
                {
                    path: "/auctions/:auctionId",
                    element: <AuctionsPage />,
                },
                {
                    path: "*",
                    element: (
                        <Navigate to={`/dashboard/${tabTypes[0]}`} replace />
                    ),
                },
            ],
        },
    ]);
}
