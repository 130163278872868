import { Chip, styled } from "@mui/material";
import { LevelEnum } from "src/enums/messagesEnum";

const LevelChip = ({ type }: { type: LevelEnum }) => {
    const colors = {
        info: {
            text: "#1890FF",
            bg: "#E6F7FF",
        },
        warn: {
            text: "#EB8E51",
            bg: "#FFE4AE",
        },
        danger: {
            text: "#FF4D4F",
            bg: "#FFF1F0",
        },
    };

    const StyledChip = styled(Chip)(() => ({
        fontSize: "12px",
        borderRadius: 5,
        height: "25px",
    }));

    switch (type) {
        case LevelEnum.INFO:
            return (
                <StyledChip
                    label="Info"
                    sx={{ color: colors.info.text, bgcolor: colors.info.bg }}
                />
            );
        case LevelEnum.WARN:
            return (
                <StyledChip
                    label="Warning"
                    sx={{ color: colors.warn.text, bgcolor: colors.warn.bg }}
                />
            );
        case LevelEnum.DANGER:
            return (
                <StyledChip
                    label="Danger"
                    sx={{
                        color: colors.danger.text,
                        bgcolor: colors.danger.bg,
                    }}
                />
            );
        default:
            return (
                <StyledChip
                    label="Info"
                    sx={{ color: colors.info.text, bgcolor: colors.info.bg }}
                />
            );
    }
};

export default LevelChip;
