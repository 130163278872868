import { Box } from "@mui/material";
import { createContext, useState } from "react";
import { priorityLevels } from "src/data/constants";
// import { tldResponseData } from "src/data/test/allBidsData";
import { validators } from "src/data/validators";
import useMessage from "src/hooks/useMessage";
import useData from "src/hooks/useApi";
import {
    AllBidsContextType,
    DomainListType,
    PopupStateType,
    ResponsePopupDataType,
    TldPricesType,
} from "src/types/allBidsPageTypes";
import { calculateBidValueBigInt } from "src/utils/helperFunctions";
import Big from "big.js";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const psl = require("psl");

export const AllBidsContext = createContext<AllBidsContextType>(
    {} as AllBidsContextType,
);

export const AllBidsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const { showError } = useMessage();
    const [popupState, setPopupState] = useState<PopupStateType>({
        id: 0,
        domain: "",
        priority: null,
        tld: "",
        regFee: 0,
        successFee: 0,
    });
    const [domainNames, setDomainNames] = useState<DomainListType[]>([]);
    const [popupLoading, setPopupLoading] = useState<boolean>(false);
    const [frontendErrors, setFrontendErrors] = useState<
        ResponsePopupDataType[]
    >([]);
    const { postData } = useData();

    const setupDomainList = async (domainText: string) => {
        setPopupLoading(true);
        setFrontendErrors([]);
        const dataArray: DomainListType[] = [];
        const invalidDomains: ResponsePopupDataType[] = [];
        const tlds: Set<string> = new Set();
        let counter = 1;

        domainText
            .trim()
            .split("\n")
            .forEach((domainSet) => {
                const pair = domainSet.split(",");
                // Validate domain name
                if (validators.DOMAIN_NAME.test(pair[0].trim())) {
                    // Find and add TLDs
                    const tld = psl.parse(pair[0].trim()).tld;
                    tlds.add(tld);

                    if (pair.length == 2) {
                        dataArray.push({
                            // @ts-expect-error Type error handled
                            priority: priorityLevels.includes(+pair[1].trim())
                                ? +pair[1].trim()
                                : 1,
                            domain: pair[0].trim(),
                            id: counter,
                            bid: 0,
                            tld,
                        });
                    } else if (pair.length == 1) {
                        dataArray.push({
                            priority: 1,
                            domain: pair[0].trim(),
                            amount: new Big(0),
                            id: counter,
                            tld,
                        });
                    } else {
                        invalidDomains.push({
                            domain: domainSet,
                            success: false,
                            reason: "Invalid domain",
                        });
                    }
                    counter++;
                } else {
                    if (pair.length > 0 && pair[0].trim()) {
                        invalidDomains.push({
                            domain: pair[0],
                            success: false,
                            reason: "Invalid domain",
                        });
                    }
                }
            });
        try {
            const response: any = await postData("/tld/getPrices", {
                tlds: Array.from(tlds),
            });
            if (response && response.success) {
                const data: TldPricesType[] = response.data;

                const newData: DomainListType[] = [];
                dataArray.forEach((row) => {
                    const tld = data.find((tld) => tld.tld === row.tld);
                    if (tld) {
                        newData.push({
                            ...row,
                            regFee: tld?.regFee,
                            successFee: tld?.successFee,
                            amount: calculateBidValueBigInt(
                                row.priority,
                                tld.successFee,
                                tld.regFee,
                            ),
                        });
                    } else {
                        if (row.domain.trim()) {
                            invalidDomains.push({
                                domain: row.domain,
                                success: false,
                                reason: "Domain not supported",
                            });
                        }
                    }
                });
                setDomainNames(newData);
                // console.log(newData);
            } else {
                throw new Error("Response Failed");
            }
        } catch (error) {
            setDomainNames(dataArray);
            setPopupLoading(false);
            return false;
        }
        if (invalidDomains.length > 0) {
            setFrontendErrors(invalidDomains);
            showError("Some Invalid Bids were Removed");
        }
        setPopupLoading(false);
        return true;
    };

    return (
        <AllBidsContext.Provider
            value={{
                popupState,
                setPopupState,
                setupDomainList,
                domainNames,
                loading: popupLoading,
                setLoading: setPopupLoading,
                frontendErrors,
                setFrontendErrors,
            }}
        >
            <Box>{children}</Box>
        </AllBidsContext.Provider>
    );
};
