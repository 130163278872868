import {
    Button,
    Divider,
    IconButton,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import useData from "src/hooks/useApi";
import useMessage from "src/hooks/useMessage";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    AuctionTypeEnum,
    ProgressStatusEnum,
} from "../../enums/allBidsPageEnums";
import Big from "big.js";
import { AuctionStatusEnum } from "../../types/postAuctionTypes";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const psl = require("psl");

const MyBackordersActionMenu = ({
    data,
    id,
    popupHandlers,
}: {
    id: string;
    popupHandlers: {
        topUp: (message: string, defaultAmount: number) => void;
        delete: () => void;
    };
    data: any;
}) => {
    const { setPopupState } = useBidsPopupState();
    const { fetchData } = useData();
    const { showError } = useMessage();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? `${id}` : undefined;

    async function handleTopUp() {
        try {
            const response = await fetchData("/bid/paymentPendingBalance");
            let defaultAmount: number;
            if (response > 0) {
                const amount = new Big(response);
                switch (true) {
                    case amount.lte(20):
                        defaultAmount = 20;
                        break;
                    case amount.lte(50):
                        defaultAmount = 50;
                        break;
                    case amount.lte(100):
                        defaultAmount = 100;
                        break;
                    case amount.lte(200):
                        defaultAmount = 200;
                        break;
                    default:
                        defaultAmount = 500;
                        break;
                }
                popupHandlers.topUp(
                    `Your total payment pending amount is ${amount.toFixed(
                        2,
                    )}$. You can Top-up your account  using the below options`,
                    defaultAmount,
                );
            } else {
                throw new Error("request failed");
            }
        } catch (error) {
            showError("Error in getting payment pending balance");
            console.log(error);
        }
    }

    function handleDelete() {
        if (data.domainName && data.priority) {
            const tld = psl.parse(data.domainName).tld;
            setPopupState({
                id: data ? data.id : 0,
                domain: data ? data.domainName : "",
                priority: null,
                tld,
            });
            popupHandlers.delete();
        }
    }

    return (
        <>
            <IconButton aria-describedby={popoverId} onClick={handleClick}>
                <BsThreeDotsVertical style={{ fontSize: "14px" }} />
            </IconButton>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Stack sx={allBidsRowMenuStyles.list}>
                    <Button
                        sx={allBidsRowMenuStyles.listButton}
                        onClick={handleTopUp}
                        disabled={
                            data.auctionStatus !=
                            AuctionStatusEnum.PAYMENT_PENDING
                        }
                    >
                        <AddCircleOutlineIcon
                            style={{ color: "#21B2E7", fontSize: "13px" }}
                        />
                        <Typography sx={allBidsRowMenuStyles.listText}>
                            Top-up
                        </Typography>
                    </Button>
                    <Divider />
                    <Button
                        sx={allBidsRowMenuStyles.listButton}
                        onClick={handleDelete}
                        disabled={
                            data.isLocked ||
                            data.status == ProgressStatusEnum.PENDING ||
                            data.auctionStatus ==
                                AuctionStatusEnum.PAYMENT_PENDING ||
                            data.auctionType == AuctionTypeEnum.POST
                        }
                    >
                        <BsTrash
                            style={{ color: "#FF4D4F", fontSize: "13px" }}
                        />
                        <Typography sx={allBidsRowMenuStyles.listText}>
                            Delete
                        </Typography>
                    </Button>
                </Stack>
            </Popover>
        </>
    );
};

const allBidsRowMenuStyles = {
    listButton: {
        display: "flex",
        px: 2,
        justifyContent: "flex-start",
        py: 1,
    },
    listText: {
        fontSize: "13px",
        ml: 1,
        color: "#3A354180",
        textTransform: "none",
    },
    list: {
        minWidth: "150px",
        fontSize: "13px",
    },
};

export default MyBackordersActionMenu;
