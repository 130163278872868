import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";
import { CurrentRouteProvider } from "./context/CurrentRouteContext";
import { CurrentBalanceProvider } from "./context/CurrentBalanceContext";
import { MessageProvider } from "./context/MessageContext";
import { NotificationsProvider } from "./context/NotificationContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CurrentRouteProvider>
                <CurrentBalanceProvider>
                    <MessageProvider>
                        <AuthProvider>
                            <NotificationsProvider>
                                <DataProvider>
                                    <App />
                                </DataProvider>
                            </NotificationsProvider>
                        </AuthProvider>
                    </MessageProvider>
                </CurrentBalanceProvider>
            </CurrentRouteProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
