export const paginationTypographyStyle = {
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
};

export const selectBoxStyle = {
    fontSize: "13px",
    height: "32px",
};

export const textBoxInnerStyle = {
    paddingY: 0,
    paddingX: 1,
    fontSize: "13px",
    width: "40px",
    textAlign: "center",
};

export const textBoxOuterStyle = { height: "32px" };

export const gridItemStyle = {
    display: "flex",
    alignItems: "center",
    maxHeight: "32px",
};

export const hidingItemStyle = { display: { xs: "none", sm: "flex" } };

export const ulStyle = {
    gap: { xs: "5px", md: "2px" },
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
};
