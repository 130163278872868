import AllRoutes from "./routes/AllRoutes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

// fonts
const font = "'Inter', sans-serif";

let theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: font,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 800,
            lg: 1200,
            xl: 1536,
        },
    },
});

theme = responsiveFontSizes(theme);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <AllRoutes />
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
