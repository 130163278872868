import { Box, Card, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BsXLg, BsEnvelopeFill, BsEnvelopeOpenFill } from "react-icons/bs";
import {
    Constants,
    LevelBgColors,
    LevelEnum,
    LevelTextColors,
} from "src/enums/messagingPageEnums";

type colorsType = {
    text: LevelTextColors;
    bg: LevelBgColors;
};

type PopupCardType = {
    type?: LevelEnum;
    time: string;
    id: number;
    children: string;
    markAsRead: (id: number) => void;
    messageClose: (id: number) => void;
    isRead: boolean;
    idx: number;
    allMsgAnimation: boolean;
    focusedMessage: number;
    setFocusedMessage: Dispatch<SetStateAction<number>>;
};

const MessageCard = ({
    type,
    time,
    id,
    children,
    markAsRead,
    messageClose,
    isRead,
    idx,
    allMsgAnimation,
    setFocusedMessage,
    focusedMessage,
}: PopupCardType) => {
    const [colors, setColors] = useState<colorsType>({
        text: LevelTextColors.INFO,
        bg: LevelBgColors.INFO,
    });
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [transform, setTransform] = useState<boolean>(false);
    const messageLength = children.length;

    const handleFocusMessage = () => {
        setFocusedMessage((focusedMessage) => (focusedMessage === id ? 0 : id));
    };

    useEffect(() => {
        switch (type) {
            case LevelEnum.DANGER:
                setColors({
                    text: LevelTextColors.DANGER,
                    bg: LevelBgColors.DANGER,
                });
                break;
            case LevelEnum.WARN:
                setColors({
                    text: LevelTextColors.WARN,
                    bg: LevelBgColors.WARN,
                });
                break;
            default:
                setColors({
                    text: LevelTextColors.INFO,
                    bg: LevelBgColors.INFO,
                });
                break;
        }
    }, [type]);

    useEffect(() => {
        setTransform(allMsgAnimation);
    }, [allMsgAnimation]);

    const popupCardStyles = {
        closeIconWrapper: { justifyContent: "flex-end" },

        message: {
            fontSize: 11,
            mx: 1,
            transition: "all 0.5s",
            transitionDelay: allMsgAnimation ? `${idx * 100}ms` : 0,
        },
        timeText: { fontSize: 11, color: colors.text },
        card: {
            borderColor: focusedMessage === id ? colors.text : "transparent",
            p: 1,
            bgcolor: colors.bg,
            minHeight: 40,
            borderRadius: 2,
            transition: "all 0.5s",
            transform: transform ? "translate(400px)" : "translate(0)",
            height: "auto",
            transitionDelay: allMsgAnimation ? `${idx * 100}ms` : 0,
        },
        cardClose: {
            borderColor: focusedMessage === id ? colors.text : "transparent",
            p: transform ? 0 : 1,
            bgcolor: colors.bg,
            minHeight: transform ? 0 : 40,
            borderRadius: 2,
            transition: "all 0.5s",
            transform: transform ? "translate(400px)" : "translate(0)",
            height: transform ? 0 : "auto",
            transitionDelay: allMsgAnimation ? `${idx * 100}ms` : 0,
        },
    };

    const markAsReadHandler = () => {
        handleFocusMessage();
        markAsRead(id);
    };

    return (
        <Box
            sx={{
                py: 1,
            }}
            onClick={markAsReadHandler}
        >
            <Card variant="outlined" sx={popupCardStyles.card}>
                <Stack>
                    <Stack
                        direction="row"
                        sx={popupCardStyles.closeIconWrapper}
                    >
                        <BsXLg
                            style={{
                                fontSize: 12,
                                color: colors.text,
                                cursor: "pointer",
                                transition: "all 0.5s",
                            }}
                            onClick={() => {
                                // eslint-disable-next-line no-constant-condition
                                setTransform(true);
                                setTimeout(() => {
                                    messageClose(id);
                                }, 500);
                            }}
                        />
                    </Stack>
                    <Stack direction="row" sx={{ px: 1 }}>
                        <Box sx={{ display: "flex" }}>
                            {isRead ? (
                                <BsEnvelopeOpenFill
                                    style={{
                                        color: colors.text,
                                        fontSize: 20,
                                    }}
                                />
                            ) : (
                                <BsEnvelopeFill
                                    style={{
                                        color: colors.text,
                                        fontSize: 20,
                                    }}
                                />
                            )}
                        </Box>
                        <Typography sx={popupCardStyles.message}>
                            {messageLength > Constants.POPUP_MSG_LENGTH
                                ? !(focusedMessage === id)
                                    ? children.slice(
                                          0,
                                          Constants.POPUP_MSG_LENGTH,
                                      ) + " ..."
                                    : children
                                : children}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={popupCardStyles.closeIconWrapper}
                    >
                        <Typography style={popupCardStyles.timeText}>
                            {time}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

export default MessageCard;
