import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import PopupButton from "../common/PopupButton";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import useData from "src/hooks/useApi";
import useMessage from "src/hooks/useMessage";

const DeleteModel = ({
    open,
    setOpen,
    domain,
    getData,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    domain: string;
    getData: (isReset?: boolean) => void;
}) => {
    const { postData } = useData();
    const { popupState } = useBidsPopupState();
    const { showError, showSuccess } = useMessage();
    const handleClose = () => setOpen(false);
    async function handleDelete() {
        console.log("delete ", popupState.id);
        try {
            const response: any = await postData("/bid/deleteUserBid", {
                bidId: popupState.id,
            });
            if (response?.success) {
                showSuccess(
                    `The Bid for ${popupState.domain} domain successfully deleted`,
                );
            } else {
                throw new Error("Deletion Failed");
            }
        } catch (error) {
            showError(`The Bid delete for ${popupState.domain} domain failed`);
        }
        getData();
    }
    return (
        <PopupModelWrapper
            handleClose={handleClose}
            open={open}
            title="Delete Bid"
        >
            <Stack sx={{ mx: 2.5, my: 2 }}>
                <Typography
                    sx={{
                        color: "#3A3541CC",
                        fontWeight: 500,
                        fontSize: "18px",
                        pt: 1,
                    }}
                >
                    Are you sure you want to{" "}
                    <span style={{ color: "#FF5D5F" }}>Delete</span> the bid for{" "}
                    {domain} ?
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        pb: 1,
                        color: "#3A354180",
                    }}
                >
                    This action cannot be undone
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
                <PopupButton
                    triggerFunction={() => {
                        setOpen(false);
                    }}
                    type="cancel"
                >
                    Cancel
                </PopupButton>
                <PopupButton
                    triggerFunction={() => {
                        handleDelete();
                        setOpen(false);
                    }}
                    type="proceed"
                >
                    Proceed
                </PopupButton>
            </Stack>
        </PopupModelWrapper>
    );
};

export default DeleteModel;
