import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { orderType } from "src/types/balancePageTypes";

const TableSortIcon = ({
    type,
    active,
}: {
    type: orderType;
    active: boolean;
}) => {
    return (
        <div
            style={{
                position: "relative",
                margin: 15,
                height: "20px",
            }}
        >
            <FaCaretUp
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    color: type === "asc" && active ? "gray" : "#D9D9D9",
                    width: "7px",
                    padding: 0,
                    margin: 0,
                }}
            />
            <FaCaretDown
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    color: type === "desc" && active ? "gray" : "#D9D9D9",
                    padding: 0,
                    margin: 0,
                    width: "7px",
                }}
            />
        </div>
    );
};

export default TableSortIcon;
