import { AuthAction, AuthState } from "src/types/authTypes";

export const enum authStates {
    LOGIN = "login",
    LOGOUT = "logout",
    SWITCH_COMPLETED = "completed",
}

const authReducer = (state: AuthState, action: AuthAction) => {
    switch (action.type) {
        case authStates.LOGIN:
            return {
                ...state,
                user: action.payload?.user,
                isLogged: true,
            };
        case authStates.LOGOUT:
            return {
                user: null,
                isLogged: false,
            };
        case authStates.SWITCH_COMPLETED:
            return {
                ...state,
                user: {
                    ...state.user,
                    isCompleted: action.payload.isCompleted,
                },
            };
        default:
            return state;
    }
};

export default authReducer;
