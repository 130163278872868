export const styleMeasures = {
    radius: 20,
    fontSize: 14,
};

export const inputLabelStyle = {
    fontSize: styleMeasures.fontSize,
    my: "-3px",
};

export const selectionStyle = {
    borderTopLeftRadius: styleMeasures.radius,
    borderTopRightRadius: styleMeasures.radius,
    padding: 0,
    fontSize: styleMeasures.fontSize,
    outlineColor: "white",
    width: "230px",
    minWidth: "230px",
    transition: "all 0.3s",
    "& .MuiOutlinedInput-input": {
        py: "12px",
    },
};

export const menuItemStyle = {
    justifyContent: "space-between",
    fontSize: styleMeasures.fontSize,
};

export const menuPropsStyle = {
    sx: {
        "&& .MuiMenu-paper": {
            borderBottomLeftRadius: styleMeasures.radius,
            borderBottomRightRadius: styleMeasures.radius,
        },
    },
};
