import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import {
    IconButton,
    MenuItem,
    Select,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import PaginationTable from "../table/PaginationTable";
import { priorityLevels } from "src/data/constants";
import { HeadColType } from "src/types/paginationTypes";
import { BsTrash } from "react-icons/bs";
import PopupButton from "../common/PopupButton";
import EmptyTable from "../table/EmptyTable";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import PopupLoading from "../common/PopupLoading";
import { calculateBidValueBigInt } from "src/utils/helperFunctions";
import useData from "src/hooks/useApi";
import { ResponsePopupDataType } from "src/types/allBidsPageTypes";
import useMessage from "../../hooks/useMessage";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

interface TableDataType {
    domain: string;
    priority: number;
    amount: Big.Big;
    id: number;
    regFee?: number;
    successFee?: number;
}

const rowsPerPage = 4;

const SelectPriorityModel = ({
    open,
    setOpen,
    setNextState,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setNextState: Dispatch<SetStateAction<boolean>>;
}) => {
    const [globalPriority, setGlobalPriority] = useState<number>(1);
    const { domainNames, loading, setFrontendErrors } = useBidsPopupState();
    const { postData } = useData();
    const { showError } = useMessage();

    const headRow: HeadColType[] = [
        {
            label: "Domain",
            align: "center",
        },
        {
            label: "priority",
            align: "center",
            component: (
                <Grid container display={"flex"} flexDirection={"row"}>
                    <PrioritySelector
                        setPriority={changeWholePriorities}
                        priority={globalPriority}
                        text="Priority"
                    />
                    <Tooltip
                        title={
                            <Typography
                                sx={{ color: "#605C66", fontSize: "10px" }}
                            >
                                {
                                    "You can select the priority for the bid that you are adding. If your priority is the highest, the domain is yours. If there are multiple bidders with the same highest priority, the system will create an auction."
                                }
                            </Typography>
                        }
                        arrow
                        enterTouchDelay={0}
                        componentsProps={{
                            popper: {
                                sx: {
                                    [`& .${tooltipClasses.arrow}`]: {
                                        color: "#EFD016",
                                    },
                                    [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: "#EFD016",
                                        borderRadius: 3,
                                    },
                                },
                            },
                        }}
                    >
                        <HelpOutlineIcon
                            style={{ color: "#605C66", fontSize: "1.4em" }}
                        />
                    </Tooltip>
                </Grid>
            ),
        },
        {
            label: "Bid ($)",
            align: "right",
        },
        {
            label: "Action",
            align: "center",
        },
    ];
    const handleClose = () => {
        setOpen(false);
        setGlobalPriority(1);
    };
    const [tableData, setTableData] = useState<TableDataType[]>([]);
    const [page, setPage] = React.useState(0);

    function changeWholePriorities(e: any) {
        const value = e.target.value ? +e.target.value : 1;
        setGlobalPriority(value);
        setTableData((tableData) =>
            tableData.map((row) => ({
                ...row,
                priority: value,
                amount: calculateBidValueBigInt(
                    value,
                    row.successFee,
                    row.regFee,
                ),
            })),
        );
    }

    function changeSpecificPriority(e: any, domain: string) {
        const value = e.target.value ? +e.target.value : 1;
        setTableData((tableData) =>
            tableData.map((row) =>
                domain === row.domain
                    ? {
                          ...row,
                          priority: value,
                          amount: calculateBidValueBigInt(
                              value,
                              row.successFee,
                              row.regFee,
                          ),
                      }
                    : row,
            ),
        );
    }

    function deleteSpecificRow(domain: string) {
        setTableData((tableData) =>
            tableData.filter((item) => item.domain !== domain),
        );
    }

    async function handleSubmitBids() {
        const bids: { domainName: string; priority: number }[] = [];
        try {
            tableData.forEach((item) => {
                bids.push({
                    domainName: item.domain,
                    priority: item.priority,
                });
            });
            const response: any = await postData("bid/placeBids", {
                bids,
            });
            if (response) {
                const responses: ResponsePopupDataType[] = response.map(
                    (item: any) => ({
                        domain: item.domain,
                        success: item.success,
                        reason: item.reason,
                    }),
                );
                setFrontendErrors((frontendErrors) => [
                    ...responses,
                    ...frontendErrors,
                ]);
            } else {
                throw new Error("Error occurred");
            }
            handleClose();
            setNextState(true);
        } catch (error: any) {
            if (error?.response?.data?.statusCode == 400) {
                showError(error?.response?.data?.message);
            } else {
                showError("Data fetching error");
            }
        }
    }

    useEffect(() => {
        setTableData(domainNames.length > 0 ? domainNames : []);
    }, [domainNames]);

    useEffect(() => {
        const data = tableData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );
        if (data.length == 0 && page >= 1) {
            setPage(page - 1);
        }
    }, [tableData]);

    return (
        <PopupModelWrapper
            open={open}
            handleClose={handleClose}
            width={{ xs: "80%", sm: "300px", md: "650px" }}
            title="Select Priority"
        >
            <Stack sx={{ mx: 2.5, my: 2 }}>
                {!loading ? (
                    <PaginationTable
                        rowsCount={tableData.length}
                        rowsPerPage={rowsPerPage}
                        headRow={headRow}
                        page={page + 1}
                        setPage={setPage}
                    >
                        {tableData.length > 0 ? (
                            (rowsPerPage > 0
                                ? tableData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage,
                                  )
                                : tableData
                            ).map((row: TableDataType) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                        height: "80px",
                                    }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                    >
                                        {row.domain}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                    >
                                        <PrioritySelector
                                            priority={row.priority}
                                            setPriority={(e) => {
                                                changeSpecificPriority(
                                                    e,
                                                    row.domain,
                                                );
                                            }}
                                            text=""
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                    >
                                        {row.amount.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                deleteSpecificRow(row.domain)
                                            }
                                        >
                                            <BsTrash
                                                style={{
                                                    color: "#3A3541CC",
                                                    fontSize: "15px",
                                                }}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <EmptyTable colSpan={4} msg="No Data" />
                        )}
                    </PaginationTable>
                ) : (
                    <PopupLoading />
                )}
            </Stack>
            {!loading && (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mx: 2, my: 2 }}
                >
                    <PopupButton
                        triggerFunction={handleClose}
                        type="cancel"
                        height="35px"
                    >
                        Cancel
                    </PopupButton>
                    <PopupButton
                        triggerFunction={handleSubmitBids}
                        type="proceed"
                        height="35px"
                        disabled={tableData.length === 0}
                    >
                        Submit
                    </PopupButton>
                </Stack>
            )}
        </PopupModelWrapper>
    );
};

const PrioritySelector = ({
    priority,
    setPriority,
    text,
}: {
    priority: number;
    setPriority: (e: any) => void;
    text?: string;
}) => (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
        {text ? <Typography fontSize="14px">{text}</Typography> : <></>}
        <Select
            id="demo-simple-select"
            value={priority}
            onChange={setPriority}
            sx={{
                fontSize: "12px",
                padding: 0,
                outlineColor: "white",
                width: "55px",
                textAlign: "center",
                transition: "all 0.3s",
                "& .MuiOutlinedInput-input": {
                    py: "3px",
                },
                mx: 1,
            }}
        >
            {priorityLevels.map((level) => (
                <MenuItem key={level} value={level}>
                    {level}
                </MenuItem>
            ))}
        </Select>
    </Stack>
);

export default SelectPriorityModel;
