export const footerSpacing = { xs: 2, md: 3 };

export const footerContainerStyle = {
    display: "flex",
    width: "100%",
    backgroundColor: "#FEFFE4",
    marginTop: 0,
    paddingTop: footerSpacing,
    gap: footerSpacing,
};

export const linkStyle = {
    color: "rgba(58, 53, 65, 0.8)",
    fontWeight: 500,
    fontSize: { xs: "10px", sm: "13px", md: "18px" },
    pl: "0px",
    whiteSpace: { xs: "normal", sm: "nowrap" },
    cursor: "pointer",
};

export const clickableText = {
    color: "rgba(58, 53, 65, 0.8)",
    fontWeight: 500,
    fontSize: { xs: "10px", sm: "13px", md: "18px" },
    whiteSpace: { xs: "normal", sm: "nowrap" },
    cursor: "pointer",
    textAlign: "center",
};

export const dividerStyle = {
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "rgba(216, 222, 232, 0.8)",
    alignSelf: "center",
    height: "auto",
    width: { xs: "80%", sm: "40%" },
    margin: { xs: "10px", sm: "10px" },
};
