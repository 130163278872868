import { io } from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Big from "big.js";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import AuctionsTable, {
    AuctionData,
    UserBidInfo,
} from "src/components/table/AuctionsTable";
import TablePagination from "src/components/table/TablePagination";
import { PaginationPropsType } from "src/types/paginationTypes";
import useMessage from "src/hooks/useMessage";
import useData from "src/hooks/useApi";
import { bidButtonStyles } from "src/components/common/navigationButton.style";
import {
    dateTimeConvertor,
    formatTimeRemainingSeconds,
    onlyNumbersWithDot,
    onlyNumericalKeyPress,
} from "src/utils/helperFunctions";
import {
    Box,
    Button,
    Chip,
    Grid,
    OutlinedInput,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import {
    AuctionInfoGridStyle,
    GridStyle,
    TypographyBoldContentStyle,
    TypographyContentStyle,
    TypographyCurrencyStyle,
    TypographyDomainStyle,
    TypographyHeaderStyle,
    TypographyTimeStyle,
    bidInputStyle,
    bulletStyle,
    centredGridItem,
    containerStyle,
    endedChipStyle,
    flexEndAlign,
    highBidStatusContainer,
    inlineTextStyle,
    liveChipStyle,
    outBidStatusContainer,
    pulse,
    shadedContainerStyle,
    toolTipMargin,
} from "./auctionsPage.style";
import { AddBidErrorsEnum } from "src/enums/auctionPageEnum";
import { UserStatusEnum } from "src/enums/allBidsPageEnums";
import { getToken } from "src/utils/jwtFunctions";

const socketUrl = process.env.REACT_APP_BACKEND_URL;
const stepCount = Number(process.env.REACT_APP_AUCTION_INCREMENT);

const AuctionsPage = () => {
    const { auctionId } = useParams();
    const { showSuccess, showError } = useMessage();
    const { postData } = useData();
    const theme = useTheme();
    const navigate = useNavigate();

    // JWT Token for Socket Authorization
    const token = getToken();

    // Ref to Handle Tooltip Visibility for Domain Name
    const domainNameRef = useRef<HTMLDivElement>(null);

    // State to Handle the Tooltip Visibility
    const [openDomainTooltip, setOpenDomainTooltip] = useState<boolean>(true);
    const [tooltipHover, setTooltipHover] = useState<boolean>(false);

    // Breakpoints To Handle FlipDown Precision
    const isSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isMd = useMediaQuery(theme.breakpoints.down("md"));

    // Trigger State
    const [shouldFetchData, setShouldFetchData] = useState<number>(
        Math.floor(Date.now() / 1000),
    );

    // Auction Table State
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [tableData, setTableData] = useState<AuctionData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Auction Information State
    const [auctionInfo, setAuctionInfo] = useState<{
        domain: string;
        auctionEndEpoch: number;
        dropDate: number;
        currentBid: number;
    }>();

    // Auction Live Status
    const [isAuctionLive, setIsAuctionLive] = useState<boolean>(true);

    // User Bid Info
    const [userBid, setUserBid] = useState<UserBidInfo>();

    // Max Bid Value
    const [placedBid, setPlacedBid] = useState<string>("");
    const maxBidRef = useRef<string>("0");

    // Bid Update Loading State
    const [bidUpdateLoading, setBidUpdateLoading] = useState<boolean>(false);

    // Configurable Text For How this Works Section
    const howThisWorkText = [
        { key: 0, text: "This Auction is Private." },
        { key: 1, text: "The high bidder wins the domain." },
        {
            key: 2,
            text: "Any bid placed within the last minute, will extend the auction end time by 5 minutes.",
        },
        {
            key: 3,
            text: (
                <>
                    For more details{" "}
                    <span
                        onClick={() => navigate("/how-it-works")}
                        style={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "#FFB700",
                            cursor: "pointer",
                        }}
                    >
                        click here
                    </span>
                    .
                </>
            ),
        },
    ];

    // Read Auction State from API
    async function getAuctionData() {
        if (auctionId) {
            setLoading(true);

            try {
                const payload = {
                    auctionId: parseInt(auctionId),
                    page: paginationProps.currentPage,
                    size: paginationProps.rowsPerPage,
                };
                const response: any = await postData("/bid/bidInfo", payload);

                // Updating Auction Info

                if (response?.auctionInfo) {
                    const tempAuctionInfo = {
                        domain: response.auctionInfo.domain,
                        auctionEndEpoch: Number(
                            response.auctionInfo.auctionEndEpoch,
                        ),
                        currentBid: Number(response.auctionInfo.currentBid),
                        dropDate: Number(response.auctionInfo.dropDate),
                    };
                    setAuctionInfo(tempAuctionInfo);

                    const auctionLive =
                        tempAuctionInfo.auctionEndEpoch >
                        Math.floor(Date.now() / 1000);

                    setIsAuctionLive(auctionLive);

                    if (response?.userBidInfo) {
                        const paymentPending =
                            response.userBidInfo.isPaymentPending ===
                            UserStatusEnum.PENDING;
                        setUserBid({
                            amount: new Big(response.userBidInfo.amount),
                            isHighestBid: response.userBidInfo.isHighestBid,
                            isPaymentPending: paymentPending,
                        });

                        if (!auctionLive && !paymentPending) {
                            navigate("/dashboard/postBids");
                        }
                    }
                } else {
                    navigate("/dashboard/postBids");
                }

                // populating Table Data

                if (response?.bidders) {
                    const tempBidders: AuctionData[] = response.bidders.map(
                        (bid: any, index: number) => ({
                            key: index + 1,
                            bidder: bid.username,
                            date:
                                typeof bid.date === "number"
                                    ? `${dateTimeConvertor(bid.date).date} ${
                                          dateTimeConvertor(bid.date).time
                                      }`
                                    : "-",
                            amount: new Big(bid.amount),
                            isMyBid: bid.isMyBid,
                            isHighestBid: bid.isHighestBid,
                        }),
                    );
                    setTableData(tempBidders);
                } else {
                    setTableData([]);
                }

                if (response?.count) {
                    const pageCount = Math.ceil(
                        response?.count / paginationProps.rowsPerPage,
                    );
                    setPaginationProps((prevProp) => ({
                        ...prevProp,
                        pages: pageCount,
                    }));
                }
            } catch (error) {
                setTableData([]);
                showError("Data fetching error");
            } finally {
                setLoading(false);
            }
        }
    }

    // Websocket Connection
    useEffect(() => {
        const socket = io(`${socketUrl}?token=${token}`);

        socket.on("connect", () => {
            console.log("WebSocket connected with ID:", auctionId);
        });

        socket.on("bid_updated", (data: any) => {
            const updatedAuctionId = data.auctionId?.toString();
            if (auctionId && updatedAuctionId === auctionId) {
                console.log("Should Fetch Auction Data", data);
                setShouldFetchData(Math.floor(Date.now() / 1000));
            }
        });

        return () => {
            console.log("Web Socket Closed for Auction ID:", auctionId);
            socket.disconnect();
        };
    }, []);

    // Overflow Detection for Domain Name
    useEffect(() => {
        const checkOverflow = () => {
            if (domainNameRef.current) {
                const { scrollWidth, clientWidth } = domainNameRef.current;
                if (scrollWidth > clientWidth) {
                    setOpenDomainTooltip(true);
                } else {
                    setOpenDomainTooltip(false);
                }
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, []);

    // Setting Max Bid Value
    useEffect(() => {
        if (auctionInfo && userBid) {
            let tempMaxBid;
            if (auctionInfo.currentBid + stepCount <= Number(userBid.amount)) {
                tempMaxBid = (Number(userBid.amount) + stepCount).toString();
            } else {
                tempMaxBid = (auctionInfo.currentBid + stepCount).toString();
            }
            maxBidRef.current = tempMaxBid;
            setPlacedBid(tempMaxBid);
        }
    }, [auctionInfo, userBid]);

    // Hook to update the Auction Data when socket or pagination flags
    useEffect(() => {
        getAuctionData();
    }, [paginationProps.currentPage, shouldFetchData]);

    useEffect(() => {
        if (paginationProps.currentPage > 1) {
            setPaginationProps((prevPaginationProps) => ({
                ...prevPaginationProps,
                currentPage: 1,
            }));
        } else {
            setShouldFetchData(Math.floor(Date.now() / 1000));
        }
    }, [paginationProps.rowsPerPage]);

    // Function to Add New Bid
    async function addNewBid() {
        try {
            setBidUpdateLoading(true);
            if (!placedBid) {
                throw AddBidErrorsEnum.NO_BID;
            }
            if (auctionInfo) {
                const newBid = new Big(placedBid).toFixed(2);
                if (Number(newBid) < Number(maxBidRef.current)) {
                    setPlacedBid(maxBidRef.current);
                    throw AddBidErrorsEnum.LOW_BID;
                }
                const response: any = await postData("/bid/updatePostBid", {
                    domainName: auctionInfo.domain,
                    price: newBid,
                });
                if (response.success) {
                    showSuccess(
                        `Bid for ${auctionInfo.domain} domain successfully updated`,
                    );
                } else {
                    showError(`Bid update for ${auctionInfo.domain} failed `);
                }
            }
        } catch (error: any) {
            if (error === AddBidErrorsEnum.LOW_BID) {
                showError(error);
            } else if (error === AddBidErrorsEnum.NO_BID) {
                showError(error);
            } else if (
                error?.response?.data?.message === AddBidErrorsEnum.LOW_BALANCE
            ) {
                showError(AddBidErrorsEnum.LOW_BALANCE);
            } else {
                showError("Bid update failed");
            }
        } finally {
            setTimeout(() => {
                setBidUpdateLoading(false);
            }, 2000);
        }
    }

    return (
        <Grid container sx={GridStyle} alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={9} display={"flex"}>
                {auctionInfo && (
                    <Grid container sx={AuctionInfoGridStyle}>
                        <Grid item xs={12} lg={5}>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    sx={{
                                        maxWidth: "80%",
                                    }}
                                >
                                    <Tooltip
                                        open={openDomainTooltip && tooltipHover}
                                        onOpen={() => setTooltipHover(true)}
                                        onClose={() => setTooltipHover(false)}
                                        title={auctionInfo.domain}
                                        placement="top-start"
                                        slotProps={toolTipMargin}
                                    >
                                        <Typography
                                            ref={domainNameRef}
                                            sx={{
                                                ...TypographyDomainStyle,
                                                marginTop: isSm
                                                    ? undefined
                                                    : "-5px",
                                                marginBottom: isSm
                                                    ? "18px"
                                                    : undefined,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                cursor: openDomainTooltip
                                                    ? "pointer"
                                                    : undefined,
                                            }}
                                        >
                                            {auctionInfo.domain}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={2} sx={centredGridItem}>
                                    {isAuctionLive ? (
                                        <Chip
                                            label="Live"
                                            component={"span"}
                                            sx={liveChipStyle}
                                        />
                                    ) : (
                                        <Chip
                                            label="End"
                                            component={"span"}
                                            sx={endedChipStyle}
                                        />
                                    )}
                                </Grid>
                                {isAuctionLive && (
                                    <Grid item xs={12}>
                                        <Typography sx={TypographyTimeStyle}>
                                            <HourglassBottomIcon
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            {`Time Left: ${formatTimeRemainingSeconds(
                                                auctionInfo.auctionEndEpoch,
                                            )}`}
                                        </Typography>
                                    </Grid>
                                )}
                                {auctionInfo.dropDate && (
                                    <Grid item xs={12}>
                                        <Typography sx={TypographyContentStyle}>
                                            {`Drop Date: ${
                                                dateTimeConvertor(
                                                    auctionInfo.dropDate,
                                                ).date
                                            } ${
                                                dateTimeConvertor(
                                                    auctionInfo.dropDate,
                                                ).time
                                            }`}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        ...flexEndAlign,
                                        overflow: isMd ? "auto" : undefined,
                                        marginTop: isMd ? "16px" : undefined,
                                    }}
                                >
                                    <FlipClockCountdown
                                        to={
                                            new Date(
                                                auctionInfo.auctionEndEpoch *
                                                    1000,
                                            )
                                        }
                                        labelStyle={{ fontWeight: "bold" }}
                                        digitBlockStyle={{
                                            fontSize: isMd ? "22px" : "40px",
                                            height: isMd ? "42px" : "55px",
                                            width: isMd ? "30px" : "40px",
                                        }}
                                        onComplete={() =>
                                            setIsAuctionLive(false)
                                        }
                                        hideOnComplete={false}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        ...flexEndAlign,
                                        marginBottom: isSm ? "16px" : undefined,
                                        marginTop: isSm ? "12px" : undefined,
                                        fontWeight: 600,
                                    }}
                                >
                                    {"Bid Status :"}
                                    {userBid?.isHighestBid ? (
                                        <Box sx={highBidStatusContainer}>
                                            {"You are the Highest Bidder"}
                                        </Box>
                                    ) : (
                                        <Box sx={outBidStatusContainer}>
                                            {"You've been outbid"}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} paddingTop={{ xs: 1, sm: 2, lg: 1 }}>
                            <Typography
                                onClick={() => navigate("/dashboard/postBids")}
                                sx={TypographyContentStyle}
                            >
                                Back to{" "}
                                <span
                                    style={{
                                        color: "#FFB700",
                                        cursor: "pointer",
                                        fontWeight: "700",
                                    }}
                                >
                                    My Auctions
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={3}>
                <Box
                    alignItems="left"
                    sx={containerStyle}
                    style={{
                        paddingTop: "24px",
                        paddingLeft: "30px",
                        paddingBottom: "23px",
                    }}
                >
                    <Typography sx={TypographyHeaderStyle}>
                        {"How this works"}
                    </Typography>
                    {howThisWorkText.map((instruction) => (
                        <Grid
                            key={instruction.key}
                            container
                            spacing={1}
                            alignItems="center"
                        >
                            <Grid item xs={1} alignSelf="flex-start">
                                <CircleIcon style={bulletStyle} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography
                                    sx={{
                                        ...TypographyContentStyle,
                                        color: "#615d67",
                                        fontSize: "16px",
                                    }}
                                >
                                    {instruction.text}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box alignItems="center" sx={containerStyle}>
                    {auctionInfo && (
                        <div style={shadedContainerStyle}>
                            <Grid container spacing={1} padding={4}>
                                <Grid item xs={12} lg={3}>
                                    <Typography sx={TypographyBoldContentStyle}>
                                        {"Current Bid"}
                                    </Typography>
                                    <Typography sx={TypographyCurrencyStyle}>
                                        {`$${auctionInfo.currentBid.toFixed(
                                            2,
                                        )}`}
                                        <Typography
                                            component={"span"}
                                            sx={inlineTextStyle}
                                        >
                                            {"USD"}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                {userBid && (
                                    <Grid item xs={12} lg={3}>
                                        <Typography
                                            sx={TypographyBoldContentStyle}
                                        >
                                            {"My Bid"}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...TypographyCurrencyStyle,
                                                color: userBid.isHighestBid
                                                    ? undefined
                                                    : "#ff4d4f",
                                            }}
                                        >
                                            {`$${userBid.amount.toFixed(2)}`}
                                            <Typography
                                                component={"span"}
                                                sx={{
                                                    ...inlineTextStyle,
                                                    color: "black",
                                                }}
                                            >
                                                {"USD"}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                )}
                                {userBid && (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        marginTop={isMd ? 2 : 0}
                                    >
                                        <Typography
                                            sx={TypographyBoldContentStyle}
                                        >
                                            {"Max Bid"}
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={1}
                                            sx={{ marginTop: "2px" }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                lg={8}
                                                alignContent={"center"}
                                            >
                                                <OutlinedInput
                                                    type="number"
                                                    sx={bidInputStyle}
                                                    value={placedBid}
                                                    onKeyDownCapture={
                                                        onlyNumericalKeyPress
                                                    }
                                                    onChange={(e: any) => {
                                                        setPlacedBid(
                                                            onlyNumbersWithDot(
                                                                e.target.value,
                                                            ),
                                                        );
                                                    }}
                                                    disabled={!isAuctionLive}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Button
                                                    onClick={addNewBid}
                                                    variant="outlined"
                                                    sx={{
                                                        ...bidButtonStyles,
                                                        animation:
                                                            userBid.isHighestBid
                                                                ? null
                                                                : isAuctionLive
                                                                  ? `${pulse} 1.5s infinite`
                                                                  : null,
                                                    }}
                                                    disabled={
                                                        bidUpdateLoading ||
                                                        !isAuctionLive
                                                    }
                                                >
                                                    {"Place Your Bid"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    )}
                    <AuctionsTable auctionData={tableData} loading={loading} />
                    {isAuctionLive && (
                        <TablePagination
                            paginationProps={paginationProps}
                            setPaginationProps={setPaginationProps}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AuctionsPage;
