export enum ProgressStatusEnum {
    WAITING = "Waiting",
    SUCCESS = "Success",
    FAILURE = "Failure",
    INPROGRESS = "Inprogress",
    PAYMENT = "Payment Pending",
    PENDING = "Pending",
    SUBMISSIONPEND = "Submission Pending",
    RESULT_PENDING = "Result pending",
}

export enum ProgressStatusTextColors {
    FAILURE = "#FF5D5F",
    PENDING = "#21B2E7",
    SUCCESS = "#3BB800",
    ARCHIVED = "#949197",
    PAYMENT = "#A772FF",
    SUBPEND = "#F25CFF",
    AUCTION_LOST = "#FFB700",
    RESULT_PENDING = "#00B9E1",
}

export enum ProgressStatusBgColors {
    FAILURE = "#FFD5D5",
    PENDING = "#D3F0FA",
    SUCCESS = "#D8F1CC",
    ARCHIVED = "#EFEFEF",
    PAYMENT = "#ECD4FF",
    SUBPEND = "#FFD4F8",
    AUCTION_LOST = "#FFF3D4",
    RESULT_PENDING = "#CCFFF3",
}

export enum Constants {
    POPUP_MSG_LENGTH = 50,
}

export enum UserStatusEnum {
    FAILURE = "Failure",
    PENDING = "Pending",
    SUCCESS = "Success",
    ARCHIVED = "Archived",
    WAITING = "Waiting",
    PAYMENT = "Payment Pending",
    INPROGRESS = "Inprogress",
    SUBMISSIONPEND = "Submission Pending",
    AUCTION_LOST = "Lost",
    RESULT_PENDING = "Result pending",
}

export enum PreAuctionEnum {
    FAILURE = "Failure",
    INPROGRESS = "In Progress",
    SUCCESS = "Success",
    ARCHIVED = "Archived",
    WAITING = "Waiting",
    PAYMENT = "Payment Pending",
}

export enum PostAuctionEnum {
    FAILURE = "Failure",
    INPROGRESS = "Inprogress",
    SUCCESS = "Success",
    WAITING = "Waiting",
    PAYMENT = "Payment Pending",
}

export enum AuctionTypeEnum {
    PRE = "Pre auction",
    POST = "Post auction",
}

export enum AuctionBackorderEnum {
    BAC = "Backorder",
    AUC = "Auction",
}

export enum DomainStatusEnum {
    NOT_PROCESSED = "NotProcessed",
    SUCCESS = "Success",
    FAILURE = "Failure",
    SKIPPED = "Skipped",
    DOMAIN_NOT_FOUND = "NotFound",
    AUTO_RENEWED = "Auto-Renewed",
    CANCELLED = "Cancelled",
}
