import { Box, TableCell, TableRow } from "@mui/material";
import LoadingIcon from "../common/LoadingIcon";

const LoadingTable = ({ colspan }: { colspan: number }) => {
    return (
        <TableRow sx={{ bgcolor: "#11111105" }}>
            <TableCell colSpan={colspan}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={100}
                >
                    <LoadingIcon />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default LoadingTable;
