import { Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { BsCalendar4Week } from "react-icons/bs";
import { datePickerStyle } from "./dateRangePicker.styles";

type DateRangePickerType = {
    startDate: any;
    endDate: any;
    setStartDate: Dispatch<SetStateAction<any>>;
    setEndDate: Dispatch<SetStateAction<any>>;
    maxDate?: DateTime<true> | null;
    startDateLabel?: string;
    endDateLabel?: string;
};

const DateRangePicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    maxDate,
    startDateLabel,
    endDateLabel,
}: DateRangePickerType) => {
    const startDateHandler = (value: any) => {
        const stDate = value.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
        setStartDate(stDate);
        if (endDate) {
            const edDate = stDate > endDate ? stDate + 2678400000 : endDate;

            setEndDate(
                edDate.set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999,
                }),
            );
        }
    };
    const endDateHandler = (value: any) => {
        const edDate = value.set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        });
        setEndDate(edDate);
        if (startDate) {
            const stDate = edDate < startDate ? edDate - 2678400000 : startDate;

            setStartDate(
                stDate.set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }),
            );
        }
    };
    return (
        <Grid container width={"100%"}>
            <Grid item xs={5.5}>
                <DatePicker
                    sx={datePickerStyle}
                    value={startDate ? startDate : null}
                    onChange={startDateHandler}
                    slots={{ openPickerIcon: BsCalendar4Week }}
                    maxDate={endDate}
                    label={startDateLabel ? startDateLabel : "Start Date"}
                />
            </Grid>
            <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <Typography
                    color={"#666666"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    to
                </Typography>
            </Grid>
            <Grid item xs={5.5}>
                <DatePicker
                    label={endDateLabel ? endDateLabel : "End Date"}
                    sx={datePickerStyle}
                    value={endDate ? endDate : null}
                    onChange={endDateHandler}
                    slots={{ openPickerIcon: BsCalendar4Week }}
                    maxDate={maxDate}
                    minDate={startDate}
                />
            </Grid>
        </Grid>
    );
};

export default DateRangePicker;
