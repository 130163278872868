import { Fragment, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, InputLabel, Typography, Grid, Box } from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getAuthPageContainerStyle,
    passwordRegex,
} from "src/utils/helperFunctions";
import useMessage from "src/hooks/useMessage";
import {
    formLabelStyle,
    formSubmitButtonStyle,
    formTextInputStyle,
    headerStyle,
    signInFormContainer,
} from "./Form.styles";
import SpinnerLoader from "src/components/loader/SpinnerLoader";
import PasswordField from "src/components/input/PasswordTextField";

function ChangePasswordForm() {
    const { showSuccess, showError } = useMessage();
    const navigate = useNavigate();
    const location = useLocation();

    // Location State

    const initialMessage = location.state?.message;
    const email = location.state?.email;

    // Change Password Page General State

    const [loading, setLoading] = useState(false);
    const containerStyle = useMemo(() => getAuthPageContainerStyle(window), []);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!email) {
            navigate("/forgotPassword");
        }
        if (initialMessage) {
            timeout = setTimeout(() => {
                showSuccess(initialMessage);
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, []);

    const validationSchema = yup.object({
        temporaryPassword: yup
            .string()
            .required("Temporary password is required"),
        password: yup
            .string()
            .test(
                "password",
                "Your password should include minimum 6 characters including uppercase, lowercase, numerical and special character",
                function (value) {
                    return passwordRegex.test(value as string);
                },
            )
            .required("Password is required"),
        confirmPassword: yup
            .string()
            .test(
                "passwords-match",
                "Passwords did not match",
                function (value) {
                    return this.parent.password === value;
                },
            )
            .required("Confirm password is required"),
    });

    const formik = useFormik({
        initialValues: {
            temporaryPassword: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        async onSubmit(values) {
            console.log(values);
            setLoading(true);
            try {
                const url = process.env.REACT_APP_BACKEND_URL + "/changePasswd";
                await axios.post(
                    url,
                    JSON.stringify({
                        email: email,
                        token: values.temporaryPassword,
                        password: values.password,
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    },
                );
                window.history.replaceState({}, document.title);
                setTimeout(() => {
                    navigate("/login", {
                        state: {
                            message:
                                "Your password has been changed successfully",
                        },
                    });
                }, 1000);
            } catch (error: any) {
                if (error.response?.status === 401) {
                    showError("Temporary password is wrong");
                } else if (error.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError("Password change failed");
                }
            }
            setLoading(false);
        },
    });

    return (
        <Fragment>
            <Grid
                zIndex={0}
                container
                sx={containerStyle}
                alignContent={"left"}
            >
                <Grid item xs={12} md={6} lg={4.5} sx={signInFormContainer}>
                    <Typography sx={headerStyle}>ACCOUNT RECOVERY</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <InputLabel
                            htmlFor="temporaryPassword"
                            sx={formLabelStyle}
                        >
                            Temporary Password
                        </InputLabel>
                        <PasswordField
                            form={formik}
                            sx={formTextInputStyle}
                            formValueName="temporaryPassword"
                        />
                        <InputLabel htmlFor="password" sx={formLabelStyle}>
                            New Password
                        </InputLabel>
                        <PasswordField
                            form={formik}
                            sx={formTextInputStyle}
                            formValueName="password"
                        />
                        <InputLabel
                            htmlFor="confirmPassword"
                            sx={formLabelStyle}
                        >
                            Re-enter Password
                        </InputLabel>
                        <PasswordField
                            form={formik}
                            sx={formTextInputStyle}
                            formValueName="confirmPassword"
                        />
                        <Button
                            type="submit"
                            sx={formSubmitButtonStyle}
                            disabled={loading}
                            endIcon={
                                <SpinnerLoader
                                    loading={loading}
                                    size={20}
                                    sx={{ marginLeft: "10px" }}
                                />
                            }
                        >
                            Submit
                        </Button>
                    </form>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"20px"}
                    >
                        <Typography
                            sx={{ ...formLabelStyle, marginRight: "10px" }}
                        >
                            Back to
                        </Typography>
                        <Typography
                            sx={{
                                ...formLabelStyle,
                                cursor: "pointer",
                                color: "#EFD016",
                            }}
                            onClick={() => navigate("/login")}
                        >
                            Sign In
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ChangePasswordForm;
