import "./loadingIconStyles.css";
import logo from "../../assets/images/logo.svg";

const LoadingIcon = ({ large }: { large?: boolean }) => {
    return (
        <div className={`logo-container${large ? "-l" : ""}`}>
            <img
                src={logo}
                alt="autobackorder"
                className={`logo-image${large ? "-l" : ""}`}
                loading="lazy"
            />
            <div className={`animated-circle${large ? "-l" : ""}`}></div>
        </div>
    );
};

export default LoadingIcon;
