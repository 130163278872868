import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BiSolidChevronDown } from "react-icons/bi";
import {
    typographyStyle,
    stackStyle,
    iconWrapperStyle,
} from "../common/levelChip.style";

const CollapsibleCell = ({
    text,
    component = false,
    children,
    length,
}: {
    text: string;
    component?: boolean;
    children?: React.ReactNode;
    length: number;
}) => {
    const [open, setOpen] = useState(false);
    return (
        <Stack
            direction="row"
            sx={stackStyle}
            onClick={() => {
                setOpen((open) => !open);
            }}
        >
            {open ? (
                <Box>
                    <Typography sx={typographyStyle}>{text}</Typography>
                    {component && children}
                </Box>
            ) : (
                <Box>
                    <Typography sx={typographyStyle}>{`${text.slice(
                        0,
                        length,
                    )} ...`}</Typography>
                    {component && children}
                </Box>
            )}
            <Box sx={iconWrapperStyle}>
                <BiSolidChevronDown
                    style={{
                        transform: !open ? "rotate(0deg)" : "rotate(180deg)",
                        transition: "all 0.3s",
                        color: "#00000070",
                    }}
                />
            </Box>
        </Stack>
    );
};

export default CollapsibleCell;
