import Big from "big.js";
import React, { createContext, useState } from "react";

const CurrentBalanceContext = createContext<any>(null);

export const CurrentBalanceProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [currentBalance, setCurrentBalance] = useState<Big.Big>(new Big(0));

    const setBalance = (balance: string) => {
        setCurrentBalance(new Big(balance));
    };

    return (
        <CurrentBalanceContext.Provider value={{ currentBalance, setBalance }}>
            {children}
        </CurrentBalanceContext.Provider>
    );
};

export default CurrentBalanceContext;
