import {
    Grid,
    MenuItem,
    Pagination,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { PaginationPropsType } from "src/types/paginationTypes";
import { processPageRequest } from "src/utils/helperFunctions";
import {
    gridItemStyle,
    hidingItemStyle,
    selectBoxStyle,
    paginationTypographyStyle,
    textBoxInnerStyle,
    textBoxOuterStyle,
    ulStyle,
} from "./PaginationStyle";

const TablePagination = ({
    paginationProps,
    setPaginationProps,
}: {
    paginationProps: PaginationPropsType;
    setPaginationProps: Dispatch<SetStateAction<PaginationPropsType>>;
}) => {
    const changePage = (page: number) => {
        setPaginationProps((paginationProps) => ({
            ...paginationProps,
            currentPage: page,
        }));
    };

    const changeRowsPerPage = (rpp: number) => {
        setPaginationProps((paginationProps) => ({
            ...paginationProps,
            rowsPerPage: rpp,
        }));
    };

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            paddingTop={4}
            rowSpacing={2}
        >
            <Grid
                item
                xs={12}
                md={6}
                lg={8}
                display={"flex"}
                justifyContent={{ xs: "center", md: "flex-start" }}
            >
                <Pagination
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    count={paginationProps.pages}
                    defaultPage={1}
                    siblingCount={1}
                    boundaryCount={2}
                    onChange={(e, newPage) => {
                        setPaginationProps((paginationProps) => ({
                            ...paginationProps,
                            currentPage: newPage,
                        }));
                    }}
                    page={paginationProps.currentPage}
                    sx={{ ul: ulStyle }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                container
                columnSpacing={2}
                justifyContent={{ xs: "center", md: "flex-end" }}
            >
                <Grid item {...gridItemStyle}>
                    <Typography sx={paginationTypographyStyle}>
                        Rows per page
                    </Typography>
                </Grid>
                <Grid item {...gridItemStyle}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label=""
                        onChange={(e) => {
                            changeRowsPerPage(+e.target.value);
                        }}
                        value={paginationProps.rowsPerPage}
                        sx={selectBoxStyle}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Grid>
                <Grid item {...gridItemStyle} {...hidingItemStyle}>
                    <Typography sx={paginationTypographyStyle}>
                        Go to
                    </Typography>
                </Grid>
                <Grid item {...gridItemStyle} {...hidingItemStyle}>
                    <TextField
                        id="outlined-basic"
                        label=""
                        type="number"
                        variant="outlined"
                        value={paginationProps.currentPage}
                        onChange={(e: any) => {
                            const rawPageReq = e.target.value ?? 1;

                            const processedPageReq = processPageRequest(
                                rawPageReq,
                                paginationProps.pages,
                            );

                            changePage(processedPageReq);
                        }}
                        inputProps={{
                            min: 1,
                            max: paginationProps.pages,
                            sx: textBoxInnerStyle,
                        }}
                        InputProps={{
                            sx: textBoxOuterStyle,
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TablePagination;
