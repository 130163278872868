import land1Image from "../../assets/images/land1.webp";
import land2Image from "../../assets/images/land2.webp";
import land3Image from "../../assets/images/land3.webp";
import land4Image from "../../assets/images/land4.webp";

export const featureData = [
    {
        header: "Multiple Registrars Supported",
        content:
            "By registering domains with an arsenal of over a half dozen different registrars, our drop catching system is able to run significantly faster than manual registration or those trying to register domains through software that uses only one registrar. Our system has the ability to simultaneously run and attempt to register expired domains through DynaDot, Network Solutions, NameSilo, Hexonet, RRPproxy, Name.com, OnlineNIC, and NameCheap.",
        image: land1Image,
    },
    {
        header: "Lowest Prices Around the Industry",
        content:
            "Stop paying too much for expired domain name registrations! Premium domains are an exception here, but most other backorder services online charge between $19 and $59 per catch. Our system doesn't have the overhead that these other websites do which means we're able to charge a lower fee. Avoid paying double or triple the cost of a normal registration by using AutoBackorder to catch expired domain names for slightly above the cost of registering it by hand.",
        image: land2Image,
    },
    {
        header: "Several Extensions Supported",
        content:
            "The ability for users to chase domain names in virtually every extension helps make us their one-stop-shop for drop catching expired domains. Other services only don't just charge you more, most also only support two or three different extensions. AutoBackorder and the domain registrars it utilizes have the ability to register everything from the most popular extensions, to ccTLDs, or even the newest gTLDs. If an extension is available to the public, we can probably catch it!",
        image: land3Image,
    },
    {
        header: "Automatic Drop Time Determination",
        content:
            "Determining the drop time for a variety of different domain extensions can be a difficult task. Thankfully, our team has already done all the hard work for you! When submitting a domain to our system it will determine the proper drop period for that extension and only run when needed. That means you don't have to worry about schedules or chasing after a domain during a period of time when it has no possibility of becoming available to the public.",
        image: land4Image,
    },
];

export const faqData = [
    {
        question: "HOW MUCH DOES AUTOBACKORDER COST?",
        message:
            "Our services are pay as you go. When you add a domain to the system the cost will be immediately shown. Users can change the priority of a domain if they want, but this can potentially influence the cost.",
    },
    {
        question: "WHAT FORMS OF PAYMENT ARE ACCEPTED?",
        message:
            "We use PayPal subscriptions to pay for successful catches. You can also choose to pre-fund your account with PayPal and then pay using your account balance.",
    },
    {
        question: "DOES MY COMPUTER NEED ANYTHING SPECIAL TO CATCH DOMAINS?",
        message:
            "Nope. Our system is completely web-based which means you won't need to download or install any software to begin catching domain names.",
    },
    {
        question: "WHICH DOMAIN REGISTRARS ARE CURRENTLY SUPPORTED?",
        message:
            "DynaDot, NameSilo, OnlineNIC, Name.com, NameCheap, Network Solutions, Hexonet, and RRPproxy.",
    },
    {
        question:
            "ARE THESE SERVICES GUARANTEED TO DROP CATCH EXPIRED DOMAIN NAMES?",
        message:
            "Our services are quite successful. However, no website or service can guarantee they be able to catch a specific domain once it drops. Even the biggest drop catching outlets cannot make such guarantees.",
    },
    {
        question: "HOW MANY DOMAIN NAMES CAN I CHASE EACH DAY?",
        message:
            "You can chase as many domains per day as you would like. However, your registrar account will need to have enough pre-paid funds in it to cover each of your desired registrations.",
    },
    {
        question: "WHICH LANGUAGES ARE SUPPORTED?",
        message: "Right now only English is supported.",
    },
];
