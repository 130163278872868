const boxRadius = 3;

export const darkYellow = "#F1D631";
const yellow = "#FCE55A";
export const lightYellow = "#FEFFE4";

const fontLarge = "16px";
const fontMedium = "14px";
const fontSmall = "12px";
const fontSmaller = "9px";

// *--- Top up page styles ---* //

export const containerStyle = {
    my: { xs: 1, sm: 3 },
    py: { xs: 2, sm: 4 },
    px: { xs: 1, sm: 5 },
    mx: { xs: 1, md: 3 },
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    boxShadow: { xs: 1, sm: 2 },
    borderRadius: 1,
    bgcolor: "#FFFFFF",
};

export const topUpChipStyle = {
    py: { xs: 1, md: "12px" },
    px: { xs: 1, md: 4 },
    my: 2,
    mx: { xs: 0, md: 1 },
    bgcolor: lightYellow,
    color: "#3A3541CC",
    width: { xs: 30, sm: 40, md: 50 },
    borderRadius: { xs: 5, md: boxRadius },
    border: { xs: 0, md: 1 },
    borderColor: { md: darkYellow },
    cursor: "pointer",
    "&:hover": {
        bgcolor: yellow,
    },
};

export const topUpChipText = {
    fontSize: { xs: fontSmaller, sm: fontSmall },
    fontWeight: 500,
    textAlign: "center",
};

export const topUpInsideBox = {
    bgcolor: "#F6F6F6",
    px: 2,
    py: { xs: 2, md: 2 },
    my: 1,
    mx: { xs: "auto", md: 1 },
    borderRadius: boxRadius,
    width: { xs: "90%", md: "500px" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const topUpOuterBox = {
    borderRadius: { xs: 5, sm: boxRadius },
    width: { xs: "-webkit-fill-available", md: "300px" },
    bgcolor: darkYellow,
    py: 1,
    px: 2,
    my: 1,
};

export const topUpButton = {
    px: { md: 4, xs: 2 },
    py: { xs: 1, md: "11px" },
    bgcolor: "black",
    color: "#EFD016",
    fontSize: fontSmall,
    fontWeight: 500,
    borderRadius: { xs: 5, md: boxRadius },
    alignSelf: { md: "center", xs: "flex-end" },
    my: { xs: 2, md: 0 },
    textTransform: "none",
    mx: 1,
    "&:hover": {
        bgcolor: "#605C65",
    },
};

export const balanceTitle = {
    fontSize: { xs: fontMedium, sm: fontLarge },
    fontWeight: 500,
    color: "#3A3541CC",
    my: { xs: 0, sm: 2 },
    mx: 2,
};

export const insideBoxTypography = {
    fontSize: { xs: fontMedium, md: fontLarge },
    fontWeight: 700,
    color: "#3A3541CC",
};

export const topUpSelectionChips = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    mx: 1,
};

export const topUpSelectionRightBox = {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    flex: 1,
};

// *--- Confirm box styles ---* //

export const popUpBoxStyle = {
    display: "flex",
    flexDirection: "column",
    width: 520,
    height: 120,
    boxShadow: 1,
    bgcolor: "white",
};

export const topUpBoxTopBar = {
    topBar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        // backgroundColor: darkYellow,
    },
    closeButton: {
        color: "#3A354180",
        borderRadius: 1,
        cursor: "pointer",
    },
};

export const popUpContent = {
    popUpWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 30,
        top: 0,
        left: 0,
        backdropFilter: "blur(2px)",
        width: "100%",
        height: "100%",
    },
    content: {
        bgcolor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "inherit",
        py: 1,
        px: 3,
    },
    leftContent: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignSelf: "start",
    },
    topUpAmount: {
        bgcolor: "#D8DEE8",
        px: 2,
        borderRadius: 2,
        m: 1,
    },
    buttonContent: {
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "flex-end",
        my: 2,
    },
};

export const topUpBoxButton = {
    ...topUpButton,
    bgcolor: darkYellow,
    color: "#3A3541CC",
    borderRadius: 5,
    mb: 0,
    px: 1,
    width: "100px",
    height: 35,
    "&:hover": {
        bgcolor: yellow,
    },
};
