import { Chip, styled } from "@mui/material";
import {
    SubmissionStatusBgColors,
    SubmissionStatusEnum,
    SubmissionStatusTextColors,
} from "src/enums/submissionsPageEnum";

const StatusChip = ({
    type,
    label,
}: {
    type: SubmissionStatusEnum;
    label?: string;
}) => {
    const colors = {
        success: {
            text: SubmissionStatusTextColors.SUCCESS,
            bg: SubmissionStatusBgColors.SUCCESS,
        },
        pending: {
            text: SubmissionStatusTextColors.PENDING,
            bg: SubmissionStatusBgColors.PENDING,
        },
        failure: {
            text: SubmissionStatusTextColors.FAILURE,
            bg: SubmissionStatusBgColors.FAILURE,
        },
    };

    const StyledChip = styled(Chip)(() => ({
        fontSize: "12px",
        borderRadius: 5,
        height: "25px",
    }));

    switch (type) {
        case SubmissionStatusEnum.SUCCESS:
            return (
                <StyledChip
                    label={label ?? "Success"}
                    sx={{
                        color: colors.success.text,
                        bgcolor: colors.success.bg,
                    }}
                />
            );
        case SubmissionStatusEnum.PENDING:
            return (
                <StyledChip
                    label={label ?? "Pending"}
                    sx={{
                        color: colors.pending.text,
                        bgcolor: colors.pending.bg,
                    }}
                />
            );
        case SubmissionStatusEnum.FAILURE:
            return (
                <StyledChip
                    label={label ?? "Failure"}
                    sx={{
                        color: colors.failure.text,
                        bgcolor: colors.failure.bg,
                    }}
                />
            );
        default:
            return (
                <StyledChip
                    label={label ?? "Success"}
                    sx={{
                        color: colors.failure.text,
                        bgcolor: colors.failure.bg,
                    }}
                />
            );
    }
};

export default StatusChip;
